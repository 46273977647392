<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered vjs-fluid"></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {
          autoplay: false, // 自动播放
          controls: true, // 用户可以与之交互的控件
          height: "", // 视频容器的高度，字符串或数字 单位像素 比如： height:300 or height:'300px'
          width: "", // 视频容器的宽度, 字符串或数字 单位像素
          loop: false, // 视频一结束就重新开始
          muted: false, // 默认情况下将使所有音频静音
          // aspectRatio: '16:9', // 显示比率
          preload: "metadata", // auto 自动  metadata 元数据信息 ，比如视频长度，尺寸等none 不预加载任何数据，直到用户开始播放才开始下载
          poster: "", // 播放前显示的视频画面，播放开始之后自动移除。通常传入一个URL
          // fullscreen: {
          //   options: { navigationUI: 'hide' },
          // },
          sources: [
            {
              src:
                "https://www.gdnos.com.cn/upload/shipin/2022/01/02/71b68783d7d0395b4b343e8419b85081.m3u8",
              type: "application/x-mpegURL",
            },
            {
              src:
                "https://wwwroot/www.gdnos.com.cn/upload/shipin/2022/01/15/2f4d53d94a2429e2572812bc009beb57.m3u8",
              type: "application/x-mpegURL",
            },
          ],
        };
      },
    },
    width: {
      type: String,
      default() {
        return "1000";
      },
    },
    height: {
      type: String,
      default() {
        return "600";
      },
    },
    src: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      {
        autoplay: false, // 自动播放
        controls: true, // 用户可以与之交互的控件
        height: this.height, // 视频容器的高度，字符串或数字 单位像素 比如： height:300 or height:'300px'
        width: this.width, // 视频容器的宽度, 字符串或数字 单位像素
        loop: false, // 视频一结束就重新开始
        muted: false, // 默认情况下将使所有音频静音
        // aspectRatio: '16:9', // 显示比率
        // fluid: true,
        preload: "metadata", // auto 自动  metadata 元数据信息 ，比如视频长度，尺寸等none 不预加载任何数据，直到用户开始播放才开始下载
        poster: "", // 播放前显示的视频画面，播放开始之后自动移除。通常传入一个URL
        // fullscreen: {
        //   options: { navigationUI: 'hide' },
        // },
        sources: [
          {
            src: this.src,
            type: "application/x-mpegURL",
          },
          // {
          //   src: 'https://www.gdnos.com.cn/upload/shipin/2022/01/02/71b68783d7d0395b4b343e8419b85081.m3u8',
          //   type: 'application/x-mpegURL',
          // },
        ],
      },
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
