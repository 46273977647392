<!-- 赛场视频 -->
<template>
  <div style="min-height: 100vh; background-color: #e1ffe1">
    <div>
      <span>{{ saichang }}</span>
      <el-date-picker
        v-model="bstime"
        style="margin: 0 5px"
        type="date"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        placeholder="选择比赛日期"
        @change="datechange"
      >
      </el-date-picker>
      <el-select v-model="timearea" placeholder="请选择上下午" @change="timechange">
        <el-option v-for="item in timeareas" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div
      style="height: calc(100vh - 6vw - 44px - 20px); overflow-y: auto; margin: 10px auto"
    >
      <!-- <el-carousel indicator-position="outside"
                   :autoplay="false"
                   :loop="false"
                   trigger="click"
                   height="600px">
        <el-carousel-item v-for="item in splist"
                          :key="item.id">
          <div style="margin: 0 auto;width:1000px;">
            <video-player v-if="item.yzshipin"
                          width="1000"
                          height="600"
                          :src="item.yzshipin" />
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <div v-if="splist.length === 0" style="margin: 10px auto">无数据</div>
      <div
        v-for="(item, i) in splist"
        class="pc_bm_item_box"
        :key="i"
        @click="showdetail(item)"
      >
        <div style="width: 200px; height: 300px; overflow: hidden">
          <el-image style="background: white" :src="item.img" fit="contain"></el-image>
        </div>
        <div style="overflow: hidden; height: 30px; line-height: 30px; font-size: 18px">
          <div
            style="
              float: left;
              width: 80px;
              text-align: left;
              padding-left: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ item.name }}
          </div>
          <div
            style="float: left; width: 80px; text-align: right; padding-right: 20px"
            v-show="item.xuhao != 0"
          >
            号码:{{ item.xuhao }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="showobj.xuhao + ' 号 ' + showobj.name"
      :visible.sync="show"
      @close="showobj = { yzshipin: false }"
      fullscreen
    >
      <div style="width: 1000px; margin: 0 auto">
        <video-player
          v-if="showobj.yzshipin"
          width="1000"
          height="600"
          :src="showobj.yzshipin"
        />
      </div>
      <el-button
        type="primary"
        style="text-align: center; with: 1000px; margin-top: 20px"
        @click="
          show = false;
          showobj = { yzshipin: false };
        "
        >关闭</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import VideoPlayer from "@/components/MyVideo.vue";

export default {
  data() {
    return {
      dasaiid: "",
      saichang: "",
      timearea: "",
      timeareas: ["上午", "下午"],
      bstime: "",
      splist: [],
      page: 1,
      size: 100,
      total: 0,
      show: false,
      showobj: {
        xuhao: "",
        name: "",
        img: "",
        yzshipin: false,
      },
      // src: 'https://www.gdnos.com.cn/upload/shipin/2022/01/02/71b68783d7d0395b4b343e8419b85081.m3u8',
      // type: 'application/x-mpegURL',
      // src: 'https://wwwroot/www.gdnos.com.cn/upload/shipin/2022/01/15/2f4d53d94a2429e2572812bc009beb57.m3u8',
      // type: 'application/x-mpegURL',
    };
  },

  components: {
    VideoPlayer,
  },

  computed: {},

  mounted() {
    const obj = this.$route.query;
    this.dasaiid = obj.id;
    this.saichang = obj.saichang;
    this.bstime = this.$moment().format("YYYY-MM-DD");
    const timeDis =
      this.$moment().startOf("day").valueOf() +
      12 * 60 * 60 * 1000 -
      new Date().valueOf();
    if (timeDis < 0) {
      console.log("下午");
      this.timearea = "下午";
    } else {
      console.log("上午");
      this.timearea = "上午";
    }
    this.beforegetdata();
  },

  methods: {
    // 显示视频播放页
    showdetail(val) {
      console.log("显示视频", val);
      this.showobj.yzshipin = "";
      this.showobj = JSON.parse(JSON.stringify(val));
      this.show = true;
    },
    // 时段变化
    timechange() {
      console.log(this.timearea);
      this.page = 1;
      this.getdata();
    },
    // 日期变化
    datechange() {
      console.log(this.bstime);
      this.page = 1;
      this.getdata();
    },
    // 分页大小改变
    handleSizeChange(val) {
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    // 页数变化
    handleCurrentChange(val) {
      this.page = val;
      this.getdata();
    },
    // TODO 第一次加载数据 判断参数完整情况
    beforegetdata() {
      if (this.dasaiid) {
        if (this.saichang) {
          this.getdata();
        }
      }
    },
    // TODO 获取参赛视频数据
    getdata() {
      const that = this;
      that.$axios
        .post("/dasaibaoming/getsplist", {
          page: this.page,
          size: this.size,
          dasaiid: this.dasaiid,
          saichang: this.saichang,
          bstime: this.bstime,
          timearea: this.timearea,
        })
        .then((res) => {
          console.log("大赛数据", res);
          if (res.code === 200) {
            that.splist = res.data.data;
            that.total = res.data.count;
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped>
.pc_bm_item_box {
  float: left;
  width: 200px;
  margin: 5px 5px;
  padding: 10px;
  height: 350px;
  border: 1px solid #e8e8e8;
  /* #e8e8e8 */
  background: white;
  border-radius: 10px;
  overflow: hidden;
}
</style>
