<!-- 资讯列表页 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex: 1" class="max_width">
      <!-- 左边资讯列表 -->
      <div class="zixun_left_box">
        <el-tabs v-model="activeName" @tab-click="changezixun" type="border-card">
          <el-tab-pane
            v-for="(item, i) in zixundata"
            :key="i"
            :label="item.label"
            :name="item.name"
          >
            <div>
              <router-link
                v-for="(info, j) in item.zixuns"
                class="zixun_list_item"
                :key="j"
                :to="'/zixundetail?id=' + info.id"
              >
                <div v-show="info.img" class="zixun_list_item_img_box">
                  <el-image :src="info.img" fit="contain"></el-image>
                </div>
                <div class="zixun_list_item_text_box">
                  <div class="zixun_list_item_text_box_titel">{{ info.title }}</div>
                  <div class="zixun_list_item_text_box_text">{{ info.text }}</div>
                  <div class="zixun_list_item_text_box_bottom">
                    <div class="zixun_list_item_text_box_bottom_time">
                      发布日期：{{ info.fabu_time }}
                    </div>
                    <div class="zixun_list_item_text_box_bottom_read">
                      {{ info.readnum }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 右边资讯推荐 -->
      <div class="zixun_right_box">
        <Zixuntuijian />
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Zixuntuijian from "@/components/ZiXunTuiJian.vue";

export default {
  data() {
    return {
      crumbs: [{ text: "首页", to: { path: "/" } }, { text: "资讯列表" }],
      activeName: "0",
      zixundata: [],
      // {
      //   name: '0',
      //   label: '会员专区',
      //   zixuns: [{
      //     id: 2,
      //     img: '',
      //     title: '会员 | 广东省民族管弦乐学会2021年度第二批新会员名单',
      //     text: '为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。',
      //     fabu_time: '2021-03-30',
      //     readnum: 231,
      //   }, {
      //     id: 2,
      //     img: '',
      //     title: '会员 | 广东省民族管弦乐学会2021年度第二批新会员名单',
      //     text: '为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。',
      //     fabu_time: '2021-03-30',
      //     readnum: 231,
      //   }, {
      //     id: 2,
      //     img: '',
      //     title: '会员 | 广东省民族管弦乐学会2021年度第二批新会员名单',
      //     text: '为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。',
      //     fabu_time: '2021-03-30',
      //     readnum: 231,
      //   }, {
      //     id: 2,
      //     img: require('@/assets/资讯img.png'),
      //     title: '广东省民族管弦乐学会 第五届北京竹笛邀请赛暨首届北京箫邀请赛 入围决赛名单查询',
      //     text: '广东省民族管弦乐学会第五届北京竹笛邀请赛暨首届北京箫邀请赛决赛于2020年8月15日至8月22日在《中国民乐》APP线上进行，请点击下方链接或者扫描下方二维码，输入“姓名”或者“证件号码”查询是否入围决赛。',
      //     fabu_time: '2021-03-30',
      //     readnum: 3936,
      //   }, {
      //     id: 2,
      //     img: require('@/assets/资讯img.png'),
      //     title: '广东省民族管弦乐学会 第五届北京竹笛邀请赛暨首届北京箫邀请赛 入围决赛名单查询',
      //     text: '广东省民族管弦乐学会第五届北京竹笛邀请赛暨首届北京箫邀请赛决赛于2020年8月15日至8月22日在《中国民乐》APP线上进行，请点击下方链接或者扫描下方二维码，输入“姓名”或者“证件号码”查询是否入围决赛。',
      //     fabu_time: '2021-03-30',
      //     readnum: 3936,
      //   }],
      // }, { name: '1', label: '大赛栏目', zixuns: [] }, { name: '2', label: '客服中心', zixuns: [] }, { name: '3', label: '重点新闻', zixuns: [] }, { name: '4', label: '行业动态', zixuns: [] }, { name: '5', label: '最新公告', zixuns: [] }
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
    Zixuntuijian,
  },

  computed: {},

  mounted() {
    this.getzixuntypes();
  },

  methods: {
    // TODO tab点击事件
    changezixun(tab) {
      console.log("切换资讯类型点击事件", tab);
      this.getzxdata(tab.index);
    },
    getzixuntypes() {
      const that = this;
      this.$axios
        .get("/ziXunType/getZiXunType")
        .then((res) => {
          console.log("获取资讯类别", res);
          if (res.code === 200) {
            const zxtypes_temp = [];
            for (let i = 0; i < res.data.length; i += 1) {
              zxtypes_temp.push({
                label: res.data[i].name,
                name: `${res.data[i].id}`,
                zixuns: [],
              });
            }
            // }
            if (zxtypes_temp) {
              that.zixundata = zxtypes_temp;
              that.activeName = zxtypes_temp[0].name;
              // 获取第一个资讯类别的明细数据
              that.getzxdata(0);
            }
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 获取某类资讯数据
    getzxdata(i) {
      console.log("获取某类资讯", i);
      const that = this;
      this.$axios
        .post("/ziXun/getZiXun", {
          typeid: parseInt(that.zixundata[i].name, 10),
          page: 1,
          size: 10,
        })
        .then((res) => {
          console.log("获取资讯返回", res);
          if (res.code === 200) {
            for (let j = res.data.data.length - 1; j >= 0; j -= 1) {
              res.data.data[j].text = res.data.data[j].jianjie;
            }
            that.zixundata[i].zixuns = res.data.data;
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
