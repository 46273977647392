<!-- 初赛报名资料管理 -->
<template>
  <div
    style="
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <div style="text-align: left">
      <el-select v-model="dasaiid" style="margin-left: 5px" placeholder="请选择大赛">
        <el-option
          v-for="(item, i) in dasaidata"
          :key="i"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="timeselect"
        style="margin: 0 5px"
        type="datetimerange"
        @change="changetime"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="-"
        :default-time="['00:00:00', '23:59:59']"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      >
      </el-date-picker>
      <el-input
        v-model="name"
        style="width: 10vw"
        placeholder="请输入参赛者名称查询"
      ></el-input>
      <el-input
        v-model="tel"
        style="width: 10vw; margin: 0 5px"
        placeholder="请输入报名电话查询"
      ></el-input>
      <el-input
        v-model="cardid"
        @blur="testcardid"
        style="width: 10vw; margin-right: 5px"
        placeholder="请输入证件号码查询"
      ></el-input>
      <el-select v-model="xiangmu" placeholder="请选择参赛项目">
        <el-option v-for="(item, i) in xiangmus" :key="i" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-cascader
        v-model="zubie"
        style="margin: 0 5px"
        :props="zubie_prop"
        :options="zubies"
        placeholder="请选择组别"
        @change="zubiechange"
      ></el-cascader>
      <el-input
        v-model="fudaolaoshi"
        style="width: 10vw"
        placeholder="请输入辅导老师查询"
      ></el-input>
      <el-select v-model="is_del" style="margin: 0 5px" placeholder="请选择">
        <el-option
          v-for="(item, i) in statuslist"
          :key="i"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select v-model="jiaofei_sign" placeholder="请选择缴费状态">
        <el-option label="未缴费" :value="0"> </el-option>
        <el-option label="已缴费" :value="1"> </el-option>
        <el-option label="全部" :value="null"> </el-option>
      </el-select>
      <el-select v-model="zubie_1" style="margin: 0 5px" placeholder="请选择组别">
        <el-option label="爱乐组" value="爱乐组"> </el-option>
        <el-option label="专业组" value="专业组"> </el-option>
        <el-option label="全部" value=""> </el-option>
      </el-select>
      <el-select v-model="zubie_2" placeholder="请选择类别">
        <el-option label="独奏" value="独奏"> </el-option>
        <el-option label="合奏" value="合奏"> </el-option>
        <el-option label="全部" value=""> </el-option>
      </el-select>
      <el-button type="primary" style="margin: 4px 5px" @click="search">查询</el-button>
      <el-button @click="add" style="margin: 4px 0" type="primary">新加报名</el-button>
      <el-button type="primary" @click="exportdata" style="margin: 4px 5px"
        >excel导出</el-button
      >
      <!-- <ReadExcel title="导入成绩"
                 msg="导入比赛成绩和查询密码"
                 @getResult="getchengji"></ReadExcel> -->
      <ReadExcel
        title="导入排序"
        msg="导入赛场 比赛日期 时间 排序"
        style="margin: 0 5px"
        @getResult="getpaixu"
      ></ReadExcel>
      <!-- <el-switch v-model="isshipin"
                 active-text="视频"
                 inactive-text="表格">
      </el-switch> -->
    </div>
    <div v-show="!isshipin" style="flex: 1; position: relative">
      <el-table
        :data="tableData"
        stripe
        height="calc(100vh - 200px)"
        border
        style="width: 100%; height: 100%; position: absolute"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="组别">
                <span>{{ props.row.zubie_1 }}</span>
              </el-form-item>
              <el-form-item label="演奏形式">
                <span>{{ props.row.zubie_2 }}</span>
              </el-form-item>
              <el-form-item label="年龄">
                <span>{{ props.row.age }}</span>
              </el-form-item>
              <el-form-item label="性别">
                <span v-if="props.row.sex === 1">男</span>
                <span v-else>女</span>
              </el-form-item>
              <el-form-item label="辅导老师">
                <span>{{ props.row.fudaolaoshi }}</span>
              </el-form-item>
              <el-form-item label="参赛曲目">
                <span>{{ props.row.qumu }}</span>
              </el-form-item>
              <el-form-item label="艺术简历">
                <span>{{ props.row.gereninfo }}</span>
              </el-form-item>
              <el-form-item label="户口所在地">
                <span>{{ props.row.hkszaidi }}</span>
              </el-form-item>
              <el-form-item label="生活照或艺术照片">
                <el-popover placement="top-start" title="" width="50vw" trigger="hover">
                  <el-image
                    style="height: 50vw"
                    :src="props.row.img"
                    fit="contain"
                  ></el-image>
                  <el-image
                    slot="reference"
                    style="width: 320px; height: 240px"
                    :src="props.row.img"
                    fit="contain"
                  ></el-image>
                </el-popover>
              </el-form-item>
              <el-form-item label="演奏视频">
                <div style="width: 400px; margin: 0 auto">
                  <video-player
                    v-if="props.row.yzshipin"
                    width="320"
                    height="240"
                    :src="props.row.yzshipin"
                  />
                </div>
                <!-- <video v-if="props.row.yzshipin"
                       controls
                       controlslist="nodownload"
                       preload="metadata"
                       width="320"
                       height="240"
                       :src="props.row.yzshipin">
                  您的浏览器不支持 video 标签。
                </video> -->
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="dasainame" align="center" label="大赛名称" width="180">
        </el-table-column>
        <el-table-column label="姓名" prop="name" align="center" width="180">
        </el-table-column>
        <el-table-column prop="cardid" align="center" label="证件号码" width="180">
        </el-table-column>
        <el-table-column prop="xiangmu" align="center" label="项目" width="180">
        </el-table-column>
        <el-table-column prop="zubie" align="center" label="组别" width="180">
        </el-table-column>
        <el-table-column prop="creat_time" align="center" label="报名时间" width="180">
        </el-table-column>
        <el-table-column label="缴费" align="center" width="90">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.jiaofei_sign === 1"
              @click="jiaofei(scope.row, 0)"
              type="success"
              >已缴费</el-tag
            >
            <el-tag v-else @click="jiaofei(scope.row, 1)" type="info">未缴费</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" align="center" label="修改时间" width="180">
        </el-table-column>
        <el-table-column label="状态" align="center" width="90">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_del === 0" type="success">恢复</el-tag>
            <el-tag v-else type="danger">删除</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="primary" size="small"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.is_del === 0"
              type="danger"
              @click="del(scope.row, 1)"
              size="small"
              >删除</el-button
            >
            <el-button v-else type="success" @click="del(scope.row, 0)" size="small"
              >恢复</el-button
            >
            <el-button @click="showcode(scope.row)" size="small">视频链接</el-button>
            <el-button @click="editGrade(scope.row)" type="primary" size="small"
              >初赛成绩</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-show="isshipin" style="width: 100%; flex-grow: 1">
      <el-carousel
        indicator-position="none"
        :autoplay="false"
        :loop="false"
        height="600px"
      >
        <el-carousel-item v-for="(item, i) in tableData" :key="i">
          <div style="background: #f2f2f2; height: 800px">
            <div style="line-height: 80px">
              序号:{{ i }}_排序:{{ item.xuhao }}_{{ item.zubie }}_{{ item.xiangmu }}_{{
                item.name
              }}_{{ item.qumu }}
            </div>
            <div style="width: 1000px; margin: 0 auto">
              <!-- <video-player v-if="item.yzshipin"
                            width="1000"
                            height="520"
                            :src="item.yzshipin" /> -->
              <!-- <video controls
                     controlslist="nodownload"
                     preload="metadata"
                     width="1000"
                     height="700"
                     :src="item.yzshipin">
                您的浏览器不支持 video 标签。
              </video> -->
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新建 -->
    <el-dialog title="新建" :visible.sync="addshow" width="80%">
      <el-form
        ref="addobj"
        :rules="rules"
        :model="addobj"
        label-width="100px"
        style="text-align: left"
      >
        <el-form-item label="大赛报名" prop="dasaiid">
          <el-select
            v-model="addobj.dasaiid"
            @change="selectds"
            placeholder="请选择大赛报名"
          >
            <el-option
              v-for="(item, i) in dasaidata"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="addobj.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="证件号码" prop="cardid">
          <el-input v-model="addobj.cardid" placeholder="请输入证件号码"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="addobj.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="参赛项目" prop="xiangmu">
          <el-select v-model="addobj.xiangmu" placeholder="请选择参赛项目">
            <el-option v-for="(item, i) in xiangmus" :key="i" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别" prop="zubie">
          <el-cascader
            v-model="addobj.zubie"
            style="margin: 0 5px"
            placeholder="请选择组别"
            :props="zubie_prop"
            :options="zubies"
            @change="zubiechange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="addobj.sex" :label="1">男</el-radio>
          <el-radio v-model="addobj.sex" :label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input v-model="addobj.age" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="曲目" prop="qumu">
          <el-input v-model="addobj.qumu" placeholder="请输入参赛曲目"></el-input>
        </el-form-item>
        <el-form-item label="辅导老师" prop="fudaolaoshi">
          <el-input v-model="addobj.fudaolaoshi" placeholder="请输入辅导老师"></el-input>
        </el-form-item>
        <el-form-item label="户口所在地">
          <el-input v-model="addobj.hkszaidi" placeholder="请输入户口所在地"></el-input>
        </el-form-item>
        <el-form-item label="个人艺术简历曾获何种奖励">
          <el-input
            v-model="addobj.gereninfo"
            placeholder="请输入个人艺术简历曾获何种奖励"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传生活照或艺术图片" prop="img">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="addobj.img" :src="addobj.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传演奏视频" prop="yzshipin">
          <UploadMP4
            @upload_res="uploadres"
            :limitsize="limitsize"
            tip="只能上传mp4视频文件，且不超过100MB"
          />
          <div style="width: 400px; margin: 0 auto">
            <video-player
              v-if="addobj.yzshipin"
              width="320"
              height="240"
              :src="addobj.yzshipin"
            />
          </div>
          <!-- <video v-if="addobj.yzshipin"
                 controls
                 controlslist="nodownload"
                 preload="metadata"
                 width="320"
                 height="240"
                 :src="addobj.yzshipin">
            您的浏览器不支持 video 标签。
          </video> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addshow = false">取 消</el-button>
        <el-button type="primary" @click="testadd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑初赛成绩 -->
    <el-dialog title="编辑初赛成绩" :visible.sync="editGradeshow" width="80%">
      <el-form
        ref="editobj"
        :rules="rules"
        :model="editobj"
        label-width="100px"
        style="text-align: left"
      >
        <el-form-item label="大赛报名" prop="dasaiid">
          <el-select
            v-model="editobj.dasaiid"
            @change="selectds"
            disabled
            placeholder="请选择大赛报名"
          >
            <el-option
              v-for="(item, i) in dasaidata"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editobj.name" readonly placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="初赛成绩">
          <el-input
            v-model="editobj.c_grade"
            type="number"
            placeholder="请输入初赛成绩"
          ></el-input>
        </el-form-item>
        <el-form-item label="初赛备注">
          <el-input v-model="editobj.c_beizhu" placeholder="请输入初赛成绩"></el-input>
        </el-form-item>
        <el-form-item label="进入复赛">
          <el-radio v-model="editobj.is_f" :label="0">未进入</el-radio>
          <el-radio v-model="editobj.is_f" :label="1">进入</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editGradeshow = false">取 消</el-button>
        <el-button type="primary" @click="doUpdateChusai">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      :visible.sync="editshow"
      @close="editobj = { yzshipin: false }"
      width="80%"
    >
      <el-form
        ref="editobj"
        :rules="rules"
        :model="editobj"
        label-width="100px"
        style="text-align: left"
      >
        <el-form-item label="大赛报名" prop="dasaiid">
          <el-select
            v-model="editobj.dasaiid"
            @change="selectds"
            placeholder="请选择大赛报名"
          >
            <el-option
              v-for="(item, i) in dasaidata"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editobj.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item v-show="editobj.zubie_2 == '独奏'" label="证件号码" prop="cardid">
          <el-input v-model="editobj.cardid" placeholder="请输入证件号码"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="editobj.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="参赛项目" prop="xiangmu">
          <el-select v-model="editobj.xiangmu" placeholder="请选择参赛项目">
            <el-option v-for="(item, i) in xiangmus" :key="i" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别" prop="zubie">
          <el-cascader
            v-model="editobj.zubie"
            style="margin: 0 5px"
            placeholder="请选择组别"
            :props="zubie_prop"
            :options="zubies"
            @change="zubiechange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="editobj.sex" :label="1">男</el-radio>
          <el-radio v-model="editobj.sex" :label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input v-model="editobj.age" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="曲目" prop="qumu">
          <el-input v-model="editobj.qumu" placeholder="请输入参赛曲目"></el-input>
        </el-form-item>
        <el-form-item label="辅导老师" prop="fudaolaoshi">
          <el-input v-model="editobj.fudaolaoshi" placeholder="请输入辅导老师"></el-input>
        </el-form-item>
        <el-form-item label="户口所在地">
          <el-input v-model="editobj.hkszaidi" placeholder="请输入户口所在地"></el-input>
        </el-form-item>
        <el-form-item label="个人艺术简历曾获何种奖励">
          <el-input
            v-model="editobj.gereninfo"
            placeholder="请输入个人艺术简历曾获何种奖励"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传生活照或艺术图片" prop="img">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="editobj.img" :src="editobj.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传演奏视频" prop="yzshipin">
          <UploadMP4
            @upload_res="uploadres"
            :limitsize="limitsize"
            tip="只能上传mp4视频文件，且不超过100MB"
          />
          <div style="width: 400px; margin: 0 auto">
            <video-player
              v-if="editobj.yzshipin"
              width="320"
              height="240"
              :src="editobj.yzshipin"
            />
          </div>
          <!-- <video v-if="editobj.yzshipin"
                 controls
                 controlslist="nodownload"
                 preload="metadata"
                 width="320"
                 height="240"
                 :src="editobj.yzshipin">
            您的浏览器不支持 video 标签。
          </video> -->
        </el-form-item>
        <el-form-item label="成绩">
          <el-input
            v-model="editobj.chengji"
            type="number"
            placeholder="请输入成绩"
          ></el-input>
        </el-form-item>
        <el-form-item label="查询密码">
          <el-input
            v-model="editobj.pwd"
            type="number"
            placeholder="请输入查询密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="赛场">
          <el-input v-model="editobj.saichang" placeholder="请输入赛场"></el-input>
        </el-form-item>
        <el-form-item label="比赛时间">
          <el-input
            v-model="editobj.bstime"
            placeholder="请输入比赛时间 例如 2022-01-01"
          ></el-input>
        </el-form-item>
        <el-form-item label="比赛时段">
          <el-input
            v-model="editobj.timearea"
            placeholder="请输入比赛时段 上午 下午"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序(视频播放顺序)">
          <el-input
            v-model="editobj.xuhao"
            type="number"
            placeholder="请输入排序"
          ></el-input>
        </el-form-item>
        <el-form-item label="缴费">
          <el-radio v-model="editobj.jiaofei_sign" :label="0">未缴费</el-radio>
          <el-radio v-model="editobj.jiaofei_sign" :label="1">已缴费</el-radio>
        </el-form-item>
        <el-form-item label="组别">
          <el-select v-model="editobj.zubie_1" placeholder="请选择">
            <el-option label="爱乐组" value="爱乐组"> </el-option>
            <el-option label="专业组" value="专业组"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="演奏形式">
          <el-select v-model="editobj.zubie_2" placeholder="请选择">
            <el-option label="独奏" value="独奏"> </el-option>
            <el-option label="合奏" value="合奏"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            editshow = false;
            editobj = { yzshipin: false };
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="testedit">确 定</el-button>
      </span>
    </el-dialog>
    <el-upload
      v-show="false"
      id="quill-upload"
      :action="uploadurl"
      :headers="myHeaders"
      name="file"
      multiple
      accept="image/png, image/jpeg, image/gif, image/jpg"
      :limit="1"
      :file-list="fileList"
      list-type="picture"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-error="uploadError"
      :on-success="handleExceed"
    >
    </el-upload>
    <el-dialog title="分享二维码" :visible.sync="qrcodeshow" width="240px">
      <MyQrcode v-if="qrcodeshow" :size="200" :qrcodeUrl="qrcodeurl"></MyQrcode>
    </el-dialog>
  </div>
</template>

<script>
import UploadMP4 from "@/components/UploadMP4.vue";
import ReadExcel from "@/components/ReadExcel.vue";
// import { Encrypt, Decrypt } from '@/util/secureUtil.js';
import { Decrypt } from "@/util/secureUtil";
import VideoPlayer from "@/components/MyVideo.vue";
import MyQrcode from "@/components/MyQrcode.vue";

export default {
  data() {
    return {
      tableData: [],
      addobj: {
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: "",
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
      },
      addshow: false,
      editobj: {
        id: "",
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: "",
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
        jiaofei_sign: 0,
        zubie_1: "",
        zubie_2: "",
        pwd: "",
        saichang: "",
        bstime: "",
        timearea: "",
      },
      editshow: false,
      editGradeshow: false,
      page: 1,
      size: 20,
      total: 0,
      myHeaders: { jwttoken: sessionStorage.getItem("jwt_token") },
      uploadurl: "https://www.gdnos.com.cn/api/test/uploadimg",
      fileList: [],
      dasaiid: null,
      dasaidata: [],
      name: "",
      tel: "",
      cardid: "",
      xiangmu: "",
      xiangmus: [],
      zubie: [],
      zubies: [],
      fudaolaoshi: "",
      timeselect: "",
      start_time: "",
      end_time: "",
      is_del: 0,
      statuslist: [
        { id: 0, name: "显示" },
        { id: 1, name: "隐藏" },
      ],
      rules: {
        dasaiid: [{ required: true, message: "请选择大赛报名", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        cardid: [{ required: true, validator: this.IDvalidator, trigger: "blur" }],
        tel: [
          {
            required: true,
            validator: this.Telvalidator,
            trigger: "blur",
          },
        ],
        xiangmu: [
          {
            required: true,
            message: "请选择参赛项目",
            trigger: "blur",
          },
        ],
        zubie: [
          {
            required: true,
            message: "请选择参赛组别",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
          },
        ],
        age: [
          {
            required: true,
            message: "请输入年龄",
            trigger: "blur",
          },
        ],
        qumu: [
          {
            required: true,
            message: "请输入参赛曲目",
            trigger: "blur",
          },
        ],
        fudaolaoshi: [
          {
            required: true,
            message: "请输入辅导老师",
            trigger: "blur",
          },
        ],
        img: [
          {
            required: true,
            message: "请上传照片",
            trigger: "blur",
          },
        ],
        yzshipin: [
          {
            required: true,
            message: "请上传演奏视频",
            trigger: "blur",
          },
        ],
      },
      zubie_prop: {
        value: "text",
        label: "text",
        children: "data",
      },
      limitsize: 100 * 1024 * 1024, // 限制大小 11803166 11M
      isshipin: false,
      jiaofei_sign: null,
      zubie_1: "",
      zubie_2: "",
      qrcodeshow: false,
      qrcodeurl: "",
    };
  },

  components: {
    UploadMP4,
    ReadExcel,
    VideoPlayer,
    MyQrcode,
  },

  computed: {},

  mounted() {
    this.page = 1;
    this.getdata();
    this.getdsdata();
    this.getcsxm();
    this.getzubie();
  },

  methods: {
    // TODO 显示二维码
    showcode(val) {
      this.qrcodeurl = `${window.location.origin}/oneshipin?id=${val.id}&sign=1`;
      this.qrcodeshow = true;
    },
    // TODO 获取导入排序数据
    getpaixu(val) {
      console.log("排序excel数据", val);
      const datas = [];
      val.forEach((value) => {
        if (
          value.id &&
          value["排序"] &&
          value["赛场"] &&
          value["比赛日期"] &&
          value["比赛时段"]
        ) {
          datas.push([
            value.id,
            value["排序"],
            value["赛场"],
            value["比赛日期"],
            value["比赛时段"],
          ]);
        }
      });
      console.log("导入数据", datas);
      if (datas.length > 0) {
        const that = this;
        that.$axios
          .post("/dasaibaoming/manyUpdateXuhao", {
            datas,
          })
          .then((res) => {
            console.log("导入视频顺序返回", res);
            that.getdata();
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
    // TODO 获取成绩导入数据
    getchengji(val) {
      console.log("成绩excel数据", val);
      const datas = [];
      val.forEach((value) => {
        if (value.id && value["成绩"]) {
          datas.push([value.id, value["成绩"], value["查询密码"]]);
        }
      });
      console.log("导入数据", datas);
      if (datas.length > 0) {
        const that = this;
        that.$axios
          .post("/dasaibaoming/manyUpdateChengji", {
            datas,
          })
          .then((res) => {
            console.log("导入成绩返回", res);
            that.getdata();
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
    // TODO 获取导出数据
    getexportdata(uuid) {
      const loading = this.$loading({
        lock: true,
        text: "数据导出中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const that = this;
      setTimeout(() => {
        that.$axios
          .post("/dasaibaoming/getExportData", { uuid })
          .then((res) => {
            console.log("导出数据返回", res);
            if (res.code === 200) {
              if (res.data.sign === 1) {
                loading.close();
                window.open(`https://www.gdnos.com.cn/${res.data.url}`);
                return;
              }
            }
            that.getexportdata(uuid);
          })
          .catch((err) => {
            loading.close();
            that.$message.info("导出报错,联系开发者");
            console.log("请求失败", err);
          });
      }, 5000);
    },
    // TODO 导出数据
    exportdata() {
      const that = this;
      this.$axios
        .post("/dasaibaoming/doExportData", {
          dasaiid: this.dasaiid,
          tel: this.tel,
          cardid: this.cardid,
          xiangmu: this.xiangmu,
          zubie: String(this.zubie),
          fudaolaoshi: this.fudaolaoshi,
          start_time: this.start_time,
          end_time: this.end_time,
          is_del: this.is_del,
          jiaofei_sign: this.jiaofei_sign,
          zubie_1: this.zubie_1,
          zubie_2: this.zubie_2,
          name: this.name,
        })
        .then((res) => {
          console.log("导出大赛报名数据返回", res);
          if (res.code === 200) {
            that.getexportdata(res.data);
          } else {
            that.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 选择大赛
    selectds(val) {
      console.log("选中大赛", val);
      this.dasaidata.some((ele) => {
        if (ele.id === val) {
          if (this.addshow) {
            this.addobj.dasainame = ele.name;
          }
          if (this.editshow) {
            this.editobj.dasainame = ele.name;
          }
          return true;
        }
        return false;
      });
    },
    // TODO 视频上传返回
    uploadres(res) {
      console.log("上传组件返回值", res);
      if (res.code === 200) {
        this.addobj.yzshipin = false;
        this.addobj.yzshipin = false;
        if (this.addshow) {
          this.addobj.yzshipin = `https://www.gdnos.com.cn/${res.msg}`;
        }
        if (this.editshow) {
          this.editobj.yzshipin = `https://www.gdnos.com.cn/${res.msg}`;
        }
      }
    },
    // TODO 获取组别
    getzubie() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 6 })
        .then((res) => {
          console.log("组别返回", res);
          if (res.code === 200) {
            this.zubies = JSON.parse(res.data.content);
          } else {
            that.$message.error("获取组别失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 获取参赛项目
    getcsxm() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 5 })
        .then((res) => {
          console.log("参赛项目返回", res);
          if (res.code === 200) {
            that.xiangmus = JSON.parse(res.data.content);
          } else {
            that.$message.error("获取参赛项目失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 选择时间
    changetime(val) {
      console.log("选择时间", val);
      if (val) {
        [this.start_time, this.end_time] = val;
      } else {
        this.start_time = "";
        this.end_time = "";
      }
    },
    // TODO 输入证件号码验证
    testcardid() {
      if (this.cardid && !this.IdentityCodeValid(this.cardid)) {
        this.$message.info("证件号码不正确");
      }
    },
    // TODO 获取全部大赛数据
    getdsdata() {
      const that = this;
      that.$axios
        .get("/dasai/getall")
        .then((res) => {
          console.log("全部大赛返回", res);
          if (res.code === 200) {
            that.dasaidata = res.data;
          }
        })
        .catch((err) => [console.log("请求失败", err)]);
    },
    // 港澳台证件号码码格式验证
    // 香港身份证校验
    isXgIDCard(idcard) {
      const checkIDCard = /^[A-Z]\d{6}\([\dA]\)$/;
      if (checkIDCard.test(idcard)) {
        let hash = (idcard.substring(0, 1).toUpperCase().charCodeAt() - 64) * 8;
        for (let i = 1; i <= 6; i += 1) {
          hash += parseInt(idcard.substring(i, i + 1), 10) * (8 - i);
        }
        console.log(hash);
        const validateNo = hash % 11;
        let ret = "";
        if (validateNo === 1) {
          ret = "A";
        } else {
          ret = 11 - validateNo;
        }
        if (idcard.substring(8, 9) === ret) {
          return true;
        }
        return false;
      }
      return false;
    },
    // 澳门身份证校验
    isAmIDCard(idcard) {
      const checkIDCard = /^[1|5|7]\d{6}[(\d)]{3}$/;
      if (checkIDCard.test(idcard)) {
        return true;
      }
      return false;
    },
    // 台湾身份证验证
    isTwIDCard(idcard) {
      const checkIDCard = /^[A-Z][1-2]\d{8}$/;
      if (checkIDCard.test(idcard)) {
        const area = {
          A: 10,
          B: 11,
          C: 12,
          D: 13,
          E: 14,
          F: 15,
          G: 16,
          H: 17,
          J: 18,
          K: 19,
          L: 20,
          M: 21,
          N: 22,
          P: 23,
          Q: 24,
          R: 25,
          S: 26,
          T: 27,
          U: 28,
          V: 29,
          X: 30,
          Y: 31,
          W: 32,
          Z: 33,
          I: 34,
          O: 35,
        };
        const idcard_array = idcard.split("");
        let jym =
          parseInt(area[idcard_array[0]] / 10, 10) +
          9 * (area[idcard_array[0]] % 10) +
          8 * idcard_array[1] +
          7 * idcard_array[2] +
          6 * idcard_array[3] +
          5 * idcard_array[4] +
          4 * idcard_array[5] +
          3 * idcard_array[6] +
          2 * idcard_array[7] +
          1 * idcard_array[8];
        jym = (10 - (jym % 10)) % 10;
        if (idcard_array[9] === jym) {
          return true;
        }
        return false;
      }
      return false;
    },
    // 护照校验
    checkPassport(idcard) {
      const myreg = /^[a-zA-Z0-9]{3,21}$/;
      const hz = /^(P\d{7})|(G\d{8})$/;
      if (!myreg.test(idcard)) {
        console.log("您输入的护照编号不正确!");
        return false;
      }
      if (!hz.test(idcard)) {
        console.log("您输入的护照编号不正确!");
        return false;
      }
      return true;
    },
    checkgapass(idcard) {
      const myreg = /^[HM]\d{10}$/;
      if (myreg.test(idcard)) {
        console.log("您输入的港澳居民来往内地通行证正确!");
        return true;
      }
      return false;
    },
    // TODO 身份证校验
    IDvalidator(rule, value, callback) {
      if (this.editshow && this.editobj.zubie_2 === "合奏") {
        callback();
        return;
      }
      console.log("身份证验证", value);
      const test_xg = this.isXgIDCard(value);
      const test_am = this.isAmIDCard(value);
      const test_tw = this.isTwIDCard(value);
      const test_hz = this.checkPassport(value);
      const test_ga = this.checkgapass(value);
      // 身份证校验   /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
      const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (value === "") {
        callback(new Error("请输入证件号码"));
      } else if (test_ga) {
        callback();
      } else if (test_xg) {
        callback();
      } else if (test_tw) {
        callback();
      } else if (test_am) {
        callback();
      } else if (test_hz) {
        callback();
      } else if (p.test(value) && this.IdentityCodeValid(value)) {
        let sex_temp;
        if (parseInt(value.substr(16, 1), 10) % 2 === 1) {
          sex_temp = 1;
        } else {
          sex_temp = 2;
        }
        const myDate = new Date();
        const month = myDate.getMonth() + 1;
        const day = myDate.getDate();
        let age_temp = myDate.getFullYear() - value.substring(6, 10) - 1;
        if (
          value.substring(10, 12) < month ||
          (value.substring(10, 12) === month && value.substring(12, 14) <= day)
        ) {
          age_temp += 1;
        }
        if (this.addshow) {
          this.addobj.sex = sex_temp;
          this.addobj.age = age_temp;
        }
        if (this.editshow) {
          this.editobj.sex = sex_temp;
          this.editobj.age = age_temp;
        }
        callback();
      } else {
        callback();
      }
    },
    IdentityCodeValid(code) {
      const city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
      };
      let pass = true;

      if (
        !code ||
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(
          code
        )
      ) {
        console.log("证件号码格式错误");
        pass = false;
      } else if (!city[code.substr(0, 2)]) {
        console.log("地址编码错误");
        pass = false;
      } else {
        // 18位身份证需要验证最后一位校验位
        code = code.split("");
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验位
        const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
        let sum = 0;
        let ai = 0;
        let wi = 0;
        for (let i = 0; i < 17; i += 1) {
          ai = code[i];
          wi = factor[i];
          sum += ai * wi;
        }
        const last = String(parity[sum % 11]);
        if (last.toLowerCase() !== code[17].toLowerCase()) {
          console.log("校验位错误");
          pass = false;
        }
      }
      return pass;
    },
    // TODO 手机号校验
    Telvalidator(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号有误"));
      } else {
        callback();
      }
    },
    // TODO 组别级联选择
    zubiechange() {},
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getdata();
    },
    // 查询
    search() {
      this.page = 1;
      this.getdata();
    },
    // TODO 图片上传成功
    uploadsucess(res, file, fileList) {
      console.log("上传接口返回", res);
      console.log("文件", file);
      console.log("文件列表", fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("新建参赛图片", this.addobj);
        }
        if (this.editshow) {
          this.editobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("编辑参赛图片", this.editobj);
        }
      } else {
        this.$message.error("图片上传失败");
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload(file) {
      // this.uillUpdateImg = true
      this.$message.info("图片上传中，请等待。。。");
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = (e) => {
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob(dataURI, type) {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    // TODO 上传图片
    uploadError() {
      // 图片上传失败,关闭loading
      // this.uillUpdateImg = false
      this.$message.error("图片插入失败");
    },
    // TODO 图片添加成功
    handleExceed(response, file, fileList) {
      console.log("上传接口返回", response);
      console.log("文件", file);
      console.log("文件列表", fileList);
      // let quill;
      // if (this.addshow) {
      //   quill = this.$refs.myQuillEditor_add.quill;
      // }
      // if (this.editshow) {
      const { quill } = this.$refs.myQuillEditor_edit;
      // }
      if (response.code === 200) {
        this.$message.success("上传成功");
        const length = quill.getSelection().index;
        // 插入图片 response.data.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", `https://www.gdnos.com.cn/${response.msg}`);
        // console.log('图片路径', this.$picurl + response.msg)
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      this.fileList = [];
      this.uillUpdateImg = false;
    },
    // TODO 获取大赛报名数据
    getdata() {
      const that = this;
      this.$axios
        .post("/dasaibaoming/getlist", {
          page: this.page,
          size: this.size,
          dasaiid: this.dasaiid,
          tel: this.tel,
          cardid: this.cardid,
          xiangmu: this.xiangmu,
          zubie: String(this.zubie),
          fudaolaoshi: this.fudaolaoshi,
          start_time: this.start_time,
          end_time: this.end_time,
          is_del: this.is_del,
          jiaofei_sign: this.jiaofei_sign,
          zubie_1: this.zubie_1,
          zubie_2: this.zubie_2,
          name: this.name,
        })
        .then((res) => {
          console.log("获取大赛报名返回", res);
          if (res.code === 200) {
            that.tableData = res.data.data;
            that.total = res.data.count;
            that.size = res.data.size;
          } else {
            that.tableData = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 新增大赛报名
    add() {
      console.log("新增大赛报名");
      this.addobj = {
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: [],
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
      };
      this.addshow = true;
    },
    // TODO 执行add之前校验
    testadd() {
      const that = this;
      this.$refs.addobj.validate((valid) => {
        if (!valid) {
          console.log("error submit!!");
          return false;
        }
        that.doadd();
        return true;
      });
    },
    // TODO 执行新增大赛报名
    doadd() {
      if (!this.addobj.dasaiid) {
        this.$message.info("请选择报名大赛");
        return;
      }
      if (!this.addobj.name) {
        this.$message.info("请输入姓名");
        return;
      }
      if (!this.addobj.cardid) {
        this.$message.info("请输入证件号码");
        return;
      }
      if (!this.addobj.tel) {
        this.$message.info("请输入电话号码");
        return;
      }
      if (!this.addobj.xiangmu) {
        this.$message.info("请选择参赛项目");
        return;
      }
      if (!this.addobj.zubie) {
        this.$message.info("请选择参赛组别");
        return;
      }
      if (!this.addobj.age) {
        this.$message.info("请输入年龄");
        return;
      }
      if (!this.addobj.qumu) {
        this.$message.info("请输入参赛曲目");
        return;
      }
      if (!this.addobj.fudaolaoshi) {
        this.$message.info("请输入辅导老师");
        return;
      }
      if (!this.addobj.img) {
        this.$message.info("请上传生活照或艺术照");
        return;
      }
      if (!this.addobj.yzshipin) {
        this.$message.info("请上传演奏视频");
        return;
      }
      const obj = JSON.parse(JSON.stringify(this.addobj));
      obj.zubie = String(obj.zubie);
      const that = this;
      that.$axios
        .post("/dasaibaoming/add", obj)
        .then((res) => {
          console.log("添加大赛报名返回", res);
          if (res.code === 200) {
            that.$message.success("添加大赛报名成功");
            that.page = 1;
            that.getdata();
            that.addshow = false;
          } else {
            that.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // todo 编辑初赛成绩
    editGrade(data) {
      this.editobj = JSON.parse(JSON.stringify(data));
      this.editobj.cardid = Decrypt(this.editobj.cardid);
      this.editobj.tel = Decrypt(this.editobj.tel);
      this.editGradeshow = true;
    },
    // TODO 编辑大赛报名
    edit(data) {
      console.log("编辑大赛报名", data);
      this.editobj = JSON.parse(JSON.stringify(data));
      // let text = "14051119940117473X"
      // console.log('明文', text)
      // var encrypt = Encrypt(text) // .toString()
      // console.log('密文', encrypt)
      // var decrypt = Decrypt(encrypt)
      // console.log('解密', decrypt)
      // decrypt = Decrypt('8wxzcJvrYdJmBP4lZ0Z86t/5k7sWTN7DpHjwq/lBhMs=')
      // console.log('解密python', decrypt)
      // decrypt = Decrypt('8wxzcJvrYdJmBP4lZ0Z86qsErkq5NtbPYSSmSPeRDH8=')
      // console.log('解密python', decrypt)
      this.editobj.zubie = this.editobj.zubie.split(",");
      this.editobj.cardid = Decrypt(this.editobj.cardid);
      this.editobj.tel = Decrypt(this.editobj.tel);
      this.editshow = true;
    },
    // TODO 执行edit之前校验
    testedit() {
      const that = this;
      this.$refs.editobj.validate((valid) => {
        if (!valid) {
          console.log("error submit!!");
          return false;
        }
        that.doedit();
        return true;
      });
    },
    // TODO 执行编辑
    doedit() {
      if (!this.editobj.dasaiid) {
        this.$message.info("请选择报名大赛");
        return;
      }
      if (!this.editobj.name) {
        this.$message.info("请输入姓名");
        return;
      }
      if (!this.editobj.cardid && this.editobj.zubie_2 === "独奏") {
        this.$message.info("请输入证件号码");
        return;
      }
      if (!this.editobj.tel) {
        this.$message.info("请输入电话号码");
        return;
      }
      if (!this.editobj.xiangmu) {
        this.$message.info("请选择参赛项目");
        return;
      }
      if (!this.editobj.zubie) {
        this.$message.info("请选择参赛组别");
        return;
      }
      if (!this.editobj.age && this.editobj.zubie_2 === "独奏") {
        this.$message.info("请输入年龄");
        return;
      }
      if (!this.editobj.qumu) {
        this.$message.info("请输入参赛曲目");
        return;
      }
      if (!this.editobj.fudaolaoshi) {
        this.$message.info("请输入辅导老师");
        return;
      }
      if (!this.editobj.img) {
        this.$message.info("请上传生活照或艺术照");
        return;
      }
      if (!this.editobj.yzshipin) {
        this.$message.info("请上传演奏视频");
        return;
      }
      const obj = JSON.parse(JSON.stringify(this.editobj));
      obj.zubie = String(obj.zubie);
      const that = this;
      that.$axios
        .post("/dasaibaoming/update", obj)
        .then((res) => {
          console.log("编辑大赛报名返回", res);
          if (res.code === 200) {
            that.$message.success("编辑大赛报名成功");
            that.getdata();
            that.editshow = false;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // 编辑初赛成绩
    doUpdateChusai() {
      const obj = JSON.parse(JSON.stringify(this.editobj));
      obj.zubie = String(obj.zubie);
      const that = this;
      that.$axios
        .post("/fusai/updateChuSaiGrade", obj)
        .then((res) => {
          console.log("编辑初赛成绩返回", res);
          if (res.code === 200) {
            that.$message.success("编辑初赛成绩成功");
            that.getdata();
            that.editGradeshow = false;
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 隐藏显示大赛报名
    del(data, sign) {
      console.log(`隐藏显示大赛报名${sign}`, data);
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      const that = this;
      that.$axios
        .post("/dasaibaoming/del", obj)
        .then((res) => {
          console.log("大赛报名显示状态返回", res);
          if (res.code === 200) {
            that.$message.success("大赛报名显示状态修改成功");
            that.getdata();
          } else {
            that.$message.error("大赛报名显示状态修改失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 缴费状态修改大赛报名
    jiaofei(data, sign) {
      console.log(`缴费状态大赛报名${sign}`, data);
      const obj = JSON.parse(JSON.stringify(data));
      obj.cardid = Decrypt(obj.cardid);
      obj.tel = Decrypt(obj.tel);
      obj.jiaofei_sign = sign;
      const that = this;
      that.$axios
        .post("/dasaibaoming/update", obj)
        .then((res) => {
          console.log("大赛报名缴费修改返回", res);
          if (res.code === 200) {
            that.$message.success("修改缴费状态成功");
            that.getdata();
          } else {
            that.$message.error("修改缴费状态失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
