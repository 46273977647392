import { render, staticRenderFns } from "./DangJianZiXunDetail.vue?vue&type=template&id=630d34dc&scoped=true&"
import script from "./DangJianZiXunDetail.vue?vue&type=script&lang=js&"
export * from "./DangJianZiXunDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630d34dc",
  null
  
)

export default component.exports