<!--  -->
<template>
  <div style="display: flex; width: 100vw; height: 100vh">
    <el-menu
      :default-active="$route.path"
      router
      style="width: 300px; height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <el-menu-item class="menu_item" index="/admin/center/">
        <i class="el-icon-setting"></i>
        <span slot="title">民乐大赛</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/chusai">
        <i class="el-icon-setting"></i>
        <span slot="title">初赛报名资料</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/fusai">
        <i class="el-icon-setting"></i>
        <span slot="title">决赛报名资料</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/zixuntype">
        <i class="el-icon-setting"></i>
        <span slot="title">资讯类别管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/zixun">
        <i class="el-icon-setting"></i>
        <span slot="title">资讯管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/lunbotu">
        <i class="el-icon-setting"></i>
        <span slot="title">轮播图管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/systemset">
        <i class="el-icon-setting"></i>
        <span slot="title">系统设置</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/huizhang">
        <i class="el-icon-setting"></i>
        <span slot="title">历任会长管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/lingdao">
        <i class="el-icon-setting"></i>
        <span slot="title">现任领导管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/worker">
        <i class="el-icon-setting"></i>
        <span slot="title">驻会工作人员管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/book">
        <i class="el-icon-setting"></i>
        <span slot="title">民乐报管理</span>
      </el-menu-item>
      <el-menu-item class="menu_item" index="/admin/center/fenzhi">
        <i class="el-icon-setting"></i>
        <span slot="title">分支机构管理</span>
      </el-menu-item>
    </el-menu>
    <div style="flex-grow: 1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {
    console.log(this.$route.path);
  },

  methods: {},
};
</script>
<style scoped>
.menu_item {
  text-align: left;
}
</style>
