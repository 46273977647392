<!-- 资讯推荐 -->
<template>
  <div style="width: 100%">
    <div>
      <div class="zixun_tuijian_title">最新通知</div>
      <div>
        <router-link
          v-for="(item, i) in tz_data"
          :key="i"
          :to="'/zixundetail?id=' + item.id"
          class="zixun_tuijian_tz_item"
          style="text-decoration: none"
        >
          <div class="zixun_tuijian_tz_item_text">{{ item.creat_time }}</div>
          <div class="zixun_tuijian_tz_item_title" style="text-align: left">
            {{ item.title }}
          </div>
          <div class="zixun_tuijian_tz_item_point">
            <el-image
              :src="require('@/assets/news_point.png')"
              style="width: 4px; height: 24px"
              fit="contain"
            ></el-image>
          </div>
        </router-link>
      </div>
    </div>
    <div>
      <div class="zixun_tuijian_title">活动报导</div>
      <div>
        <router-link
          v-for="(item, i) in bd_data"
          :key="i"
          :to="'/zixundetail?id=' + item.id"
          class="zixun_tuijian_bd_item"
          style="text-decoration: none"
        >
          <div class="zixun_tuijian_bd_item_img_box">
            <el-image
              :src="item.img"
              style="height: 80px; transition: all 0.3s; -webkit-transition: all 0.3s"
              fit="cover"
            ></el-image>
          </div>
          <div class="zixun_tuijian_bd_item_title" style="text-align: left">
            {{ item.title }}
          </div>
          <div class="zixun_tuijian_bd_item_text" style="text-align: left">
            {{ item.jianjie }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tz_data: [
        {
          title: "广东省民族管弦乐学会组织驻会工作人员前往国家博物馆参观学习",
          time: "2021-03-30",
          id: 1,
        },
        {
          title: "广东省民族管弦乐学会组织驻会工作人员前往国家博物馆参观学习",
          time: "2021-03-30",
          id: 1,
        },
        {
          title: "广东省民族管弦乐学会组织驻会工作人员前往国家博物馆参观学习",
          time: "2021-03-30",
          id: 1,
        },
        {
          title: "广东省民族管弦乐学会组织驻会工作人员前往国家博物馆参观学习",
          time: "2021-03-30",
          id: 1,
        },
        {
          title: "广东省民族管弦乐学会组织驻会工作人员前往国家博物馆参观学习",
          time: "2021-03-30",
          id: 1,
        },
      ], // 重点新闻数据
      bd_data: [
        {
          title: "会员 | 广东省民族管弦乐学会2021年度第二批新会员名单",
          jianjie:
            "为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。",
          img: require("@/assets/报导img.jpg"),
          id: 1,
        },
        {
          title: "会员 | 广东省民族管弦乐学会2021年度第二批新会员名单",
          jianjie:
            "为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。",
          img: require("@/assets/报导img.jpg"),
          id: 1,
        },
        {
          title: "会员 | 广东省民族管弦乐学会2021年度第二批新会员名单",
          jianjie:
            "为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。",
          img: require("@/assets/报导img.jpg"),
          id: 1,
        },
        {
          title: "会员 | 广东省民族管弦乐学会2021年度第二批新会员名单",
          jianjie:
            "为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。",
          img: require("@/assets/报导img.jpg"),
          id: 1,
        },
        {
          title: "会员 | 广东省民族管弦乐学会2021年度第二批新会员名单",
          jianjie:
            "为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。",
          img: require("@/assets/报导img.jpg"),
          id: 1,
        },
      ], // 最新公告数据
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.gettzdata();
    this.gethddata();
  },

  methods: {
    // TODO 最新通知 重点新闻数据
    gettzdata() {
      const that = this;
      this.$axios
        .post("/ziXun/getZiXunByType", {
          page: 1,
          size: 5,
          typeid: 4,
        })
        .then((res) => {
          console.log("获取重点新闻列表返回", res);
          if (res.code === 200) {
            that.tz_data = res.data.data;
          }
          //  else {
          //   that.tz_data = [];
          // }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 活动报导 最新公告数据
    gethddata() {
      const that = this;
      this.$axios
        .post("/ziXun/getZiXunByType", {
          page: 1,
          size: 5,
          typeid: 6,
        })
        .then((res) => {
          console.log("获取最新公告列表返回", res);
          if (res.code === 200) {
            that.bd_data = res.data.data;
          }
          //  else {
          //   that.tz_data = [];
          // }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
