<!-- 轮播图 -->
<template>
  <div>
    <div style="text-align: left; margin: 5px 0">
      <el-button @click="add" type="primary">新加轮播图</el-button>
      <el-select
        v-model="is_del"
        style="margin: 0 5px"
        @change="changestatus"
        placeholder="请选择轮播图状态"
      >
        <el-option
          v-for="(item, i) in is_dels"
          :key="i"
          :label="item.name"
          :value="item.status"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column align="center" label="轮播图" width="400">
        <template slot-scope="scope">
          <img :src="scope.row.img" class="lunbotu_img" />
        </template>
      </el-table-column>
      <el-table-column label="关联资讯标题" prop="zixuntitle" align="center" width="180">
      </el-table-column>
      <el-table-column prop="paixu" align="center" label="排序" width="80">
      </el-table-column>
      <el-table-column prop="update_time" align="center" label="修改时间" width="180">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button
            v-if="scope.row.is_del === 0"
            type="danger"
            @click="del(scope.row, 1)"
            size="small"
            >隐藏</el-button
          >
          <el-button v-else type="success" @click="del(scope.row, 0)" size="small"
            >显示</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新建 -->
    <el-dialog title="新建" :visible.sync="addshow" width="80%">
      <el-form ref="addobj" :model="addobj" label-width="100px" style="text-align: left">
        <el-form-item label="轮播图">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="addobj.img" :src="addobj.img" class="lunbotu_img" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="关联资讯">
          <el-select
            v-model="addobj.zixunid"
            filterable
            remote
            reserve-keyword
            placeholder="请输入标题选择关联资讯"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in zixuns"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="addobj.paixu"
            type="number"
            placeholder="请输入排序数字越大越在前"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addshow = false">取 消</el-button>
        <el-button type="primary" @click="doadd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="editshow" width="80%">
      <el-form
        ref="editobj"
        :model="editobj"
        label-width="100px"
        style="text-align: left"
      >
        <el-form-item label="轮播图">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="editobj.img" :src="editobj.img" class="lunbotu_img" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="选择关联资讯">
          <el-select
            v-model="editobj.zixunid"
            filterable
            remote
            reserve-keyword
            placeholder="请输入标题选择关联资讯"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in zixuns"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="editobj.paixu"
            placeholder="请输入排序数字越大越在前"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editshow = false">取 消</el-button>
        <el-button type="primary" @click="doedit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      addobj: {
        img: "",
        zixunid: "",
        paixu: "",
      },
      addshow: false,
      editobj: {
        id: "",
        img: "",
        zixunid: "",
        paixu: "",
      },
      editshow: false,
      is_dels: [
        { name: "显示", status: 0 },
        { name: "隐藏", status: 1 },
      ],
      is_del: 0,
      page: 1,
      size: 20,
      total: 0,
      myHeaders: { jwttoken: sessionStorage.getItem("jwt_token") },
      uploadurl: "https://www.gdnos.com.cn/api/test/uploadimg",
      fileList: [],
      kwd: "",
      zixuns: [],
      loading: false,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getdata();
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getdata();
    },
    // TODO 上传图片之前开启loading
    beforeUpload(file) {
      // this.uillUpdateImg = true
      this.$message.info("图片上传中，请等待。。。");
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = (e) => {
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob(dataURI, type) {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    // TODO 图片上传成功
    uploadsucess(res, file, fileList) {
      console.log("上传接口返回", res);
      console.log("文件", file);
      console.log("文件列表", fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("新建轮播图上传轮播图", this.addobj);
        }
        if (this.editshow) {
          this.editobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("编辑轮播图上传轮播图", this.editobj);
        }
      } else {
        this.$message.error("图片上传失败");
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        this.$axios
          .post("/ziXun/getTitle", {
            kwd: query,
          })
          .then((res) => {
            that.loading = false;
            console.log("获取资讯返回", res);
            if (res.code === 200) {
              that.zixuns = res.data;
            } else {
              that.zixuns = [];
            }
          })
          .catch((err) => {
            that.loading = false;
            console.log("请求失败", err);
            that.zixuns = [];
          });
      } else {
        this.zixuns = [];
      }
    },
    // TODO 获取资讯数据
    getzixundata() {
      const that = this;
      this.$axios
        .post("/ziXun/getTitle", {
          kwd: this.kwd,
        })
        .then((res) => {
          console.log("获取资讯返回", res);
          if (res.code === 200) {
            that.zixuns = res.data;
          } else {
            that.zixuns = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 修改状态获取轮播图数据
    changestatus() {
      this.page = 1;
      this.getdata();
    },
    // TODO 获取轮播图数据
    getdata() {
      const that = this;
      this.$axios
        .post("/lunbotu/getList", {
          page: this.page,
          size: this.size,
          is_del: this.is_del,
        })
        .then((res) => {
          console.log("获取轮播图返回", res);
          if (res.code === 200) {
            that.tableData = res.data.data;
            that.page = res.data.page;
            that.size = res.data.size;
            that.total = res.data.total;
          } else {
            that.tableData = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 新增轮播图
    add() {
      console.log("新加轮播图");
      this.addobj = {
        img: "",
        zixunid: "",
        paixu: "",
      };
      this.addshow = true;
    },
    // TODO 执行新增轮播图
    doadd() {
      console.log("执行新加轮播图");
      if (this.addobj.img && this.addobj.zixunid) {
        const that = this;
        this.$axios
          .post("/lunbotu/add", this.addobj)
          .then((res) => {
            console.log("添加轮播图返回", res);
            if (res.code === 200) {
              that.addobj = {
                img: "",
                zixunid: "",
                paixu: "",
              };
              that.addshow = false;
              that.$message.success("添加轮播图成功");
              that.page = 1;
              that.getdata();
            } else {
              that.$message.info("添加轮播图失败");
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      } else if (this.addobj.img) {
        this.$message.info("未选择关联资讯");
      } else {
        this.$message.info("未选择轮播图片");
      }
    },
    // TODO 编辑轮播图
    edit(data) {
      console.log("编辑轮播图", data);
      this.editobj = JSON.parse(JSON.stringify(data));
      this.editshow = true;
    },
    // TODO 执行编辑轮播图
    doedit() {
      console.log("执行编辑轮播图");
      if (this.editobj.img && this.editobj.zixunid) {
        const that = this;
        this.$axios
          .post("/lunbotu/update", this.editobj)
          .then((res) => {
            console.log("修改轮播图返回", res);
            if (res.code === 200) {
              that.editobj = {
                img: "",
                zixunid: "",
                paixu: "",
              };
              that.editshow = false;
              that.$message.success("修改轮播图成功");
              that.getdata();
            } else {
              that.$message.info("修改轮播图失败");
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      } else if (this.editobj.img) {
        this.$message.info("未选择关联资讯");
      } else {
        this.$message.info("未选择轮播图片");
      }
    },
    // TODO 启用停用轮播图
    del(data, sign) {
      let action;
      if (sign === 0) {
        action = "显示";
      } else {
        action = "隐藏";
      }
      console.log("修改轮播图状态", action);
      const that = this;
      this.$axios
        .post("/lunbotu/del", {
          id: data.id,
          is_del: sign,
        })
        .then((res) => {
          console.log("修改轮播图返回", res);
          if (res.code === 200) {
            that.$message.success(`${action}轮播图成功`);
            that.is_del = sign;
            that.getdata();
          } else {
            that.$message.info(`${action}修改轮播图失败`);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
