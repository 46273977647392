<!-- 民乐大赛 -->
<template>
  <div>民乐大赛</div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style scoped></style>
