<!-- 大赛报名 -->
<template>
  <div>
    <div
      v-if="ispc"
      style="
        height: 100vh;
        display: flex;
        width: 100vw;
        flex-direction: column;
        background: #d9ecff;
      "
    >
      <div
        style="
          font-weight: bold;
          text-align: center;
          font-size: 3.4vw;
          height: 6vw;
          line-height: 6vw;
        "
      >
        {{ dasai_title }}
      </div>
      <!-- <div class="right_btn"
           v-if="ispc">
        <el-button @click="getbg"
                   v-show="active===1"
                   type="primary">报名资料查询</el-button>
      </div> -->
      <van-tabs
        v-model="active"
        title-active-color="#409EFF"
        style="flex: 1"
        @click="tabClick"
        color="#409EFF"
      >
        <van-tab v-if="js_img" title="大赛介绍">
          <div
            style="
              height: calc(100vh - 6vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
            "
          >
            <el-image :src="js_img" style="width: 50vw" fit="contain">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </van-tab>
        <van-tab title="独奏报名">
          <div
            style="
              height: calc(100vh - 6vw - 44px - 62px);
              overflow-y: auto;
              width: 50vw;
              margin: 10px auto;
              padding: 0 25vw;
            "
          >
            <el-form
              ref="addobj"
              :rules="rules"
              :model="addobj"
              label-position="left"
              :label-width="label_width"
              style="text-align: left"
            >
              <el-form-item label="大赛报名" prop="dasaiid">
                <el-select
                  v-model="addobj.dasaiid"
                  style="width: 100%"
                  @change="selectds"
                  placeholder="请选择大赛报名"
                >
                  <el-option
                    v-for="(item, i) in dasaidata"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="addobj.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="证件号码" prop="cardid">
                <el-input v-model="addobj.cardid" placeholder="请输入证件号码"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="tel">
                <el-input v-model="addobj.tel" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="参赛项目" prop="xiangmu">
                <el-select
                  v-model="addobj.xiangmu"
                  style="width: 100%"
                  placeholder="请选择参赛项目"
                >
                  <el-option
                    v-for="(item, i) in xiangmus"
                    :key="i"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="组别" prop="zubie">
                <el-cascader
                  v-model="addobj.zubie"
                  style="width: 100%"
                  placeholder="请选择组别"
                  :props="zubie_prop"
                  :options="zubies"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <el-radio v-model="addobj.sex" :label="1">男</el-radio>
                <el-radio v-model="addobj.sex" :label="2">女</el-radio>
              </el-form-item>
              <el-form-item label="年龄" prop="age">
                <el-input v-model="addobj.age" placeholder="请输入年龄"></el-input>
              </el-form-item>
              <el-form-item label="曲目" prop="qumu">
                <el-input v-model="addobj.qumu" placeholder="请输入参赛曲目"></el-input>
              </el-form-item>
              <el-form-item label="辅导老师" prop="fudaolaoshi">
                <el-input
                  v-model="addobj.fudaolaoshi"
                  placeholder="请输入辅导老师"
                ></el-input>
              </el-form-item>
              <el-form-item label="户口所在地">
                <el-input
                  v-model="addobj.hkszaidi"
                  placeholder="请输入户口所在地"
                ></el-input>
              </el-form-item>
              <el-form-item label="个人艺术简历曾获何种奖励">
                <el-input
                  v-model="addobj.gereninfo"
                  placeholder="请输入个人艺术简历曾获何种奖励"
                ></el-input>
              </el-form-item>
              <el-form-item label="上传生活照或艺术图片" prop="img">
                <el-upload
                  :action="uploadurl"
                  :headers="myHeaders"
                  name="file"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="uploadsucess"
                >
                  <img v-if="addobj.img" :src="addobj.img" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="spcontrol" label="上传演奏视频" prop="yzshipin">
                <UploadMP4
                  @upload_res="uploadres"
                  :limitsize="limitsize"
                  tip="只能上传mp4视频文件，且不超过100MB"
                />
                <video-player
                  v-if="addobj.yzshipin"
                  width="320"
                  height="240"
                  :src="addobj.yzshipin"
                />
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button type="primary" style="width: 50vw" @click="testadd"
              >提 交 报 名</el-button
            >
          </div>
        </van-tab>
        <van-tab title="组合报名">
          <div
            style="
              height: calc(100vh - 6vw - 44px - 62px);
              overflow-y: auto;
              width: 50vw;
              margin: 10px auto;
              padding: 0 25vw;
            "
          >
            <el-form
              ref="zuheobj"
              :rules="zhrules"
              :model="zuheobj"
              label-position="left"
              :label-width="label_width"
              style="text-align: left"
            >
              <el-form-item label="大赛报名" prop="dasaiid">
                <el-select
                  v-model="zuheobj.dasaiid"
                  style="width: 100%"
                  @change="selectds"
                  placeholder="请选择大赛报名"
                >
                  <el-option
                    v-for="(item, i) in dasaidata"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="团体名称" prop="name">
                <el-input v-model="zuheobj.name" placeholder="请输入团体"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="tel">
                <el-input v-model="zuheobj.tel" placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item label="参赛项目" prop="xiangmu">
                <el-select
                  v-model="zuheobj.xiangmu"
                  style="width: 100%"
                  placeholder="请选择参赛项目"
                >
                  <el-option
                    v-for="(item, i) in xiangmus"
                    :key="i"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="组别" prop="zubie">
                <el-cascader
                  v-model="zuheobj.zubie"
                  style="width: 100%"
                  placeholder="请选择组别"
                  :props="zubie_prop"
                  :options="zubies"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="所有成员名字">
                <el-input
                  v-model="zuheobj.hkszaidi"
                  placeholder="请输入所有成员名字"
                ></el-input>
              </el-form-item>
              <el-form-item label="曲目" prop="qumu">
                <el-input v-model="zuheobj.qumu" placeholder="请输入参赛曲目"></el-input>
              </el-form-item>
              <el-form-item label="辅导老师" prop="fudaolaoshi">
                <el-input
                  v-model="zuheobj.fudaolaoshi"
                  placeholder="请输入辅导老师"
                ></el-input>
              </el-form-item>
              <el-form-item label="团体艺术简历曾获何种奖励">
                <el-input
                  v-model="zuheobj.gereninfo"
                  placeholder="请输入团体艺术简历曾获何种奖励"
                ></el-input>
              </el-form-item>
              <el-form-item label="上传生活照或艺术图片" prop="img">
                <el-upload
                  :action="uploadurl"
                  :headers="myHeaders"
                  name="file"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="uploadsucess"
                >
                  <img v-if="zuheobj.img" :src="zuheobj.img" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="spcontrol" label="上传演奏视频" prop="yzshipin">
                <UploadMP4
                  @upload_res="uploadres"
                  :limitsize="limitsize"
                  tip="只能上传mp4视频文件，且不超过100MB"
                />
                <video-player
                  v-if="zuheobj.yzshipin"
                  width="320"
                  height="240"
                  :src="zuheobj.yzshipin"
                />
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button type="primary" style="width: 50vw" @click="testadd"
              >提 交 报 名</el-button
            >
          </div>
        </van-tab>
        <van-tab title="爱乐组">
          <div
            style="
              height: calc(100vh - 6vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
            "
          >
            <div style="width: 50vw; margin: 5px auto">
              <el-input
                placeholder="请输入姓名关键字模糊搜索"
                v-model="kwd"
                style="width: 40vw"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  @click="dosousuo"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <div
                style="
                  width: 10vw;
                  text-align: center;
                  display: inline-block;
                  vertical-align: top;
                  height: 40px;
                  line-height: 40px;
                "
              >
                <el-switch
                  v-model="juesaishow"
                  @change="changeBisai"
                  active-text="决赛"
                  inactive-text="初赛"
                >
                </el-switch>
              </div>
            </div>
            <div v-if="baomingdata.length === 0" style="margin: 10px auto">无数据</div>
            <div
              v-for="(item, i) in baomingdata"
              class="pc_bm_item_box"
              :key="i"
              @click="showdetail(item)"
            >
              <div style="width: 200px; height: 300px; overflow: hidden">
                <el-image
                  style="background: white"
                  :src="item.img"
                  fit="contain"
                ></el-image>
              </div>
              <div
                style="overflow: hidden; height: 30px; line-height: 30px; font-size: 18px"
              >
                <div
                  style="
                    float: left;
                    width: 80px;
                    text-align: left;
                    padding-left: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.name }}
                </div>
                <div
                  style="float: left; width: 80px; text-align: right; padding-right: 20px"
                  v-show="!juesaishow && item.xuhao != 0"
                >
                  号码:{{ item.xuhao }}
                </div>
                <div
                  style="float: left; width: 80px; text-align: right; padding-right: 20px"
                  v-show="juesaishow && item.f_xuhao != 0"
                >
                  号码:{{ item.f_xuhao }}
                </div>
              </div>
            </div>
            <div style="clear: both" v-show="page < maxpage">
              <el-button type="primary" style="width: 50vw" @click="getmoredata"
                >获取更多</el-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab title="专业组">
          <div
            style="
              height: calc(100vh - 6vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
            "
          >
            <div style="width: 50vw; margin: 5px auto">
              <el-input
                placeholder="请输入姓名关键字模糊搜索"
                v-model="kwd"
                style="width: 40vw"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  @click="dosousuo"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <div
                style="
                  width: 10vw;
                  text-align: center;
                  display: inline-block;
                  vertical-align: top;
                  height: 40px;
                  line-height: 40px;
                "
              >
                <el-switch
                  v-model="juesaishow"
                  @change="changeBisai"
                  active-text="决赛"
                  inactive-text="初赛"
                >
                </el-switch>
              </div>
            </div>
            <div v-if="baomingdata.length === 0" style="margin: 10px auto">无数据</div>
            <div
              v-for="(item, i) in baomingdata"
              class="pc_bm_item_box"
              :key="i"
              @click="showdetail(item)"
            >
              <div style="width: 200px; height: 300px; overflow: hidden">
                <el-image
                  style="background: white"
                  :src="item.img"
                  fit="contain"
                ></el-image>
              </div>
              <div
                style="overflow: hidden; height: 30px; line-height: 30px; font-size: 18px"
              >
                <div
                  style="
                    float: left;
                    width: 80px;
                    text-align: left;
                    padding-left: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.name }}
                </div>
                <div
                  style="float: left; width: 80px; text-align: right; padding-right: 20px"
                  v-show="!juesaishow && item.xuhao != 0"
                >
                  号码:{{ item.xuhao }}
                </div>
                <div
                  style="float: left; width: 80px; text-align: right; padding-right: 20px"
                  v-show="juesaishow && item.f_xuhao != 0"
                >
                  号码:{{ item.f_xuhao }}
                </div>
              </div>
            </div>
            <div style="clear: both" v-show="page < maxpage">
              <el-button type="primary" style="width: 50vw" @click="getmoredata"
                >获取更多</el-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab title="组合组">
          <div
            style="
              height: calc(100vh - 6vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
            "
          >
            <div style="width: 50vw; margin: 5px auto">
              <el-input
                placeholder="请输入姓名关键字模糊搜索"
                v-model="kwd"
                style="width: 40vw"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  @click="dosousuo"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <div
                style="
                  width: 10vw;
                  text-align: center;
                  display: inline-block;
                  vertical-align: top;
                  height: 40px;
                  line-height: 40px;
                "
              >
                <el-switch
                  v-model="juesaishow"
                  @change="changeBisai"
                  active-text="决赛"
                  inactive-text="初赛"
                >
                </el-switch>
              </div>
            </div>
            <div v-if="baomingdata.length === 0" style="margin: 10px auto">无数据</div>
            <div
              v-for="(item, i) in baomingdata"
              class="pc_bm_item_box"
              :key="i"
              @click="showdetail(item)"
            >
              <div style="width: 200px; height: 300px; overflow: hidden">
                <el-image
                  style="background: white"
                  :src="item.img"
                  fit="contain"
                ></el-image>
              </div>
              <div
                style="overflow: hidden; height: 30px; line-height: 30px; font-size: 18px"
              >
                <div
                  style="
                    float: left;
                    width: 80px;
                    text-align: left;
                    padding-left: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.name }}
                </div>
                <div
                  style="float: left; width: 80px; text-align: right; padding-right: 20px"
                  v-show="!juesaishow && item.xuhao != 0"
                >
                  号码:{{ item.xuhao }}
                </div>
                <div
                  style="float: left; width: 80px; text-align: right; padding-right: 20px"
                  v-show="juesaishow && item.f_xuhao != 0"
                >
                  号码:{{ item.f_xuhao }}
                </div>
              </div>
            </div>
            <div style="clear: both" v-show="page < maxpage">
              <el-button type="primary" style="width: 50vw" @click="getmoredata"
                >获取更多</el-button
              >
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div
      v-else
      style="
        height: 100vh;
        display: flex;
        width: 100vw;
        flex-direction: column;
        background: #d9ecff;
      "
    >
      <div
        style="
          font-weight: bold;
          text-align: center;
          font-size: 6vw;
          height: 12vw;
          line-height: 12vw;
        "
      >
        {{ dasai_title }}
      </div>
      <van-tabs
        v-model="active"
        title-active-color="#409EFF"
        style="flex: 1"
        @click="tabClick"
        color="#409EFF"
      >
        <van-tab v-if="js_img" title="大赛介绍">
          <el-image :src="js_img" style="width: 100vw" fit="contain">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </van-tab>
        <van-tab title="独奏报名">
          <div
            style="
              height: calc(100vh - 12vw - 44px - 62px);
              overflow-y: auto;
              width: 90vw;
              margin: 10px auto;
              padding: 0 5vw;
            "
          >
            <el-form
              ref="addobj"
              :rules="rules"
              :model="addobj"
              label-position="right"
              :label-width="label_width"
              style="text-align: left"
            >
              <el-form-item label="大赛报名" prop="dasaiid">
                <el-select
                  v-model="addobj.dasaiid"
                  style="width: 100%"
                  @change="selectds"
                  placeholder="请选择大赛报名"
                >
                  <el-option
                    v-for="(item, i) in dasaidata"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="addobj.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="证件号码" prop="cardid">
                <el-input v-model="addobj.cardid" placeholder="请输入证件号码"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="tel">
                <el-input v-model="addobj.tel" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="参赛项目" prop="xiangmu">
                <el-select
                  v-model="addobj.xiangmu"
                  style="width: 100%"
                  placeholder="请选择参赛项目"
                >
                  <el-option
                    v-for="(item, i) in xiangmus"
                    :key="i"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="组别" prop="zubie">
                <el-cascader
                  v-model="addobj.zubie"
                  style="width: 100%"
                  placeholder="请选择组别"
                  :props="zubie_prop"
                  :options="zubies"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <el-radio v-model="addobj.sex" :label="1">男</el-radio>
                <el-radio v-model="addobj.sex" :label="2">女</el-radio>
              </el-form-item>
              <el-form-item label="年龄" prop="age">
                <el-input v-model="addobj.age" placeholder="请输入年龄"></el-input>
              </el-form-item>
              <el-form-item label="曲目" prop="qumu">
                <el-input v-model="addobj.qumu" placeholder="请输入参赛曲目"></el-input>
              </el-form-item>
              <el-form-item label="辅导老师" prop="fudaolaoshi">
                <el-input
                  v-model="addobj.fudaolaoshi"
                  placeholder="请输入辅导老师"
                ></el-input>
              </el-form-item>
              <el-form-item label="户口所在地">
                <el-input
                  v-model="addobj.hkszaidi"
                  placeholder="请输入户口所在地"
                ></el-input>
              </el-form-item>
              <el-form-item label="个人艺术简历曾获何种奖励">
                <el-input
                  v-model="addobj.gereninfo"
                  placeholder="请输入个人艺术简历曾获何种奖励"
                ></el-input>
              </el-form-item>
              <el-form-item label="上传生活照或艺术图片" prop="img">
                <el-upload
                  :action="uploadurl"
                  :headers="myHeaders"
                  name="file"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="uploadsucess"
                >
                  <img v-if="addobj.img" :src="addobj.img" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="spcontrol" label="上传演奏视频" prop="yzshipin">
                <UploadMP4
                  @upload_res="uploadres"
                  :limitsize="limitsize"
                  tip="只能上传mp4视频文件，且不超过100MB"
                />
                <video-player
                  v-if="addobj.yzshipin"
                  width="320"
                  height="240"
                  :src="addobj.yzshipin"
                />
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button type="primary" style="width: 90vw" @click="testadd"
              >提 交 报 名</el-button
            >
          </div>
        </van-tab>
        <van-tab title="组合报名">
          <div
            style="
              height: calc(100vh - 12vw - 44px - 62px);
              overflow-y: auto;
              width: 90vw;
              margin: 10px auto;
              padding: 0 5vw;
            "
          >
            <el-form
              ref="zuheobj"
              :rules="zhrules"
              :model="zuheobj"
              label-position="right"
              :label-width="label_width"
              style="text-align: left"
            >
              <el-form-item label="大赛报名" prop="dasaiid">
                <el-select
                  v-model="zuheobj.dasaiid"
                  style="width: 100%"
                  @change="selectds"
                  placeholder="请选择大赛报名"
                >
                  <el-option
                    v-for="(item, i) in dasaidata"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="团体名称" prop="name">
                <el-input v-model="zuheobj.name" placeholder="请输入团体名称"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="tel">
                <el-input v-model="zuheobj.tel" placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item label="参赛项目" prop="xiangmu">
                <el-select
                  v-model="zuheobj.xiangmu"
                  style="width: 100%"
                  placeholder="请选择参赛项目"
                >
                  <el-option
                    v-for="(item, i) in xiangmus"
                    :key="i"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="组别" prop="zubie">
                <el-cascader
                  v-model="zuheobj.zubie"
                  style="width: 100%"
                  placeholder="请选择组别"
                  :props="zubie_prop"
                  :options="zubies"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="所有成员名字">
                <el-input
                  v-model="zuheobj.hkszaidi"
                  placeholder="请输入所有成员名字"
                ></el-input>
              </el-form-item>
              <el-form-item label="曲目" prop="qumu">
                <el-input v-model="zuheobj.qumu" placeholder="请输入参赛曲目"></el-input>
              </el-form-item>
              <el-form-item label="辅导老师" prop="fudaolaoshi">
                <el-input
                  v-model="zuheobj.fudaolaoshi"
                  placeholder="请输入辅导老师"
                ></el-input>
              </el-form-item>
              <el-form-item label="团体艺术简历曾获何种奖励">
                <el-input
                  v-model="zuheobj.gereninfo"
                  placeholder="请输入团体艺术简历曾获何种奖励"
                ></el-input>
              </el-form-item>
              <el-form-item label="上传生活照或艺术图片" prop="img">
                <el-upload
                  :action="uploadurl"
                  :headers="myHeaders"
                  name="file"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-success="uploadsucess"
                >
                  <img v-if="zuheobj.img" :src="zuheobj.img" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="spcontrol" label="上传演奏视频" prop="yzshipin">
                <UploadMP4
                  @upload_res="uploadres"
                  :limitsize="limitsize"
                  tip="只能上传mp4视频文件，且不超过100MB"
                />
                <video-player
                  v-if="zuheobj.yzshipin"
                  width="320"
                  height="240"
                  :src="zuheobj.yzshipin"
                />
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button type="primary" style="width: 90vw" @click="testadd"
              >提 交 报 名</el-button
            >
          </div>
        </van-tab>
        <van-tab title="爱乐组">
          <div
            style="
              height: calc(100vh - 12vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
              padding: 0 2vw;
              width: 96vw;
            "
          >
            <van-field
              v-model="kwd"
              clearable
              style="width: 92vw; margin: 0 2vw"
              v-show="m_show === false"
              placeholder="请输入姓名关键字模糊搜索"
            >
              <template #button>
                <van-button size="small" @click="dosousuo" type="info">搜索</van-button>
              </template>
            </van-field>
            <div style="height: 40px; line-height: 40px">
              <el-switch
                v-model="juesaishow"
                @change="changeBisai"
                active-text="决赛"
                style="font-size: 5vw"
                inactive-text="初赛"
              ></el-switch>
            </div>
            <div
              v-if="baomingdata.length === 0 && m_show === false"
              style="margin: 2vw auto"
            >
              无数据
            </div>
            <ul
              v-show="m_show === false"
              class="infinite-list"
              v-infinite-scroll="getmoredata"
              style="overflow: auto; height: calc(100vh - 12vw - 44px - 140px)"
            >
              <div
                v-for="(item, i) in baomingdata"
                class="m_bm_item_box"
                :key="i"
                @click="m_showdetail(item)"
              >
                <div style="height: 60vw">
                  <el-image
                    style="background: white; width: calc(45vw - 20px)"
                    :src="item.img"
                    fit="contain"
                  ></el-image>
                </div>
                <div
                  style="overflow: hidden; height: 8vw; line-height: 8vw; font-size: 4vw"
                >
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: left;
                      padding-left: 1vw;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: right;
                      padding-right: 1vw;
                    "
                    v-show="!juesaishow && item.xuhao != 0"
                  >
                    号码:{{ item.xuhao }}
                  </div>
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: right;
                      padding-right: 1vw;
                    "
                    v-show="juesaishow && item.f_xuhao != 0"
                  >
                    号码:{{ item.f_xuhao }}
                  </div>
                </div>
              </div>
            </ul>
            <!-- <div style="clear:both"
                 v-show="page<maxpage && m_show===false">
              <el-button type="primary"
                         style="width:92vw;"
                         @click="getmoredata">获取更多</el-button>
            </div> -->
            <div v-show="m_show" style="text-align: left">
              <div class="m_detail_box">
                <div
                  style="
                    height: 10vw;
                    line-height: 10vw;
                    color: black;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ showobj.name }}
                </div>
                <el-image style="width: 90vw" :src="showobj.img" fit="contain"></el-image>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">参赛项目</div>
                <div>{{ showobj.xiangmu }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">组别</div>
                <div>{{ showobj.zubie }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">性别</div>
                <div>
                  <span v-if="showobj.sex === 1">男</span>
                  <span v-else>女</span>
                </div>
              </div>
              <div class="m_detail_box" v-show="!juesaishow">
                <div class="m_detail_box_item_1">初赛曲目</div>
                <div>{{ showobj.qumu }}</div>
              </div>
              <div class="m_detail_box" v-show="juesaishow">
                <div class="m_detail_box_item_1">决赛曲目</div>
                <div>{{ showobj.qumu }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">个人艺术简历曾获何种奖励</div>
                <div>{{ showobj.gereninfo }}</div>
              </div>
              <div class="m_detail_box" v-show="!juesaishow">
                <div class="m_detail_box_item_1">初赛演奏视频</div>
                <div v-if="spcontrol" style="width: 90vw">
                  <video-player
                    v-if="showobj.yzshipin"
                    width="320"
                    height="160"
                    :src="showobj.yzshipin"
                  />
                </div>
              </div>
              <div class="m_detail_box" v-show="juesaishow">
                <div class="m_detail_box_item_1">决赛演奏视频</div>
                <div v-if="spcontrol" style="width: 90vw">
                  <video-player
                    v-if="showobj.f_shipin"
                    width="320"
                    height="160"
                    :src="showobj.f_shipin"
                  />
                </div>
              </div>
              <div style="height: 50px"></div>
              <el-button
                type="primary"
                style="width: 90vw; position: fixed; bottom: 0; left: 5vw"
                @click="
                  m_show = false;
                  showobj = { yzshipin: false, f_shipin: false };
                "
                >关 闭</el-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab title="专业组">
          <div
            style="
              height: calc(100vh - 12vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
              padding: 0 2vw;
              width: 96vw;
            "
          >
            <van-field
              v-model="kwd"
              clearable
              style="width: 92vw; margin: 0 2vw"
              v-show="m_show === false"
              placeholder="请输入姓名关键字模糊搜索"
            >
              <template #button>
                <van-button size="small" @click="dosousuo" type="info">搜索</van-button>
              </template>
            </van-field>
            <div style="height: 40px; line-height: 40px">
              <el-switch
                v-model="juesaishow"
                @change="changeBisai"
                active-text="决赛"
                style="font-size: 5vw"
                inactive-text="初赛"
              ></el-switch>
            </div>
            <div
              v-if="baomingdata.length === 0 && m_show === false"
              style="margin: 2vw auto"
            >
              无数据
            </div>
            <ul
              v-show="m_show === false"
              class="infinite-list"
              v-infinite-scroll="getmoredata"
              style="overflow: auto; height: calc(100vh - 12vw - 44px - 140px)"
            >
              <div
                v-for="(item, i) in baomingdata"
                class="m_bm_item_box"
                :key="i"
                @click="m_showdetail(item)"
              >
                <div style="height: 60vw">
                  <el-image
                    style="background: white; width: calc(45vw - 20px)"
                    :src="item.img"
                    fit="contain"
                  ></el-image>
                </div>
                <div
                  style="overflow: hidden; height: 8vw; line-height: 8vw; font-size: 4vw"
                >
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: left;
                      padding-left: 1vw;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: right;
                      padding-right: 1vw;
                    "
                    v-show="!juesaishow && item.xuhao != 0"
                  >
                    号码:{{ item.xuhao }}
                  </div>
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: right;
                      padding-right: 1vw;
                    "
                    v-show="juesaishow && item.f_xuhao != 0"
                  >
                    号码:{{ item.f_xuhao }}
                  </div>
                </div>
              </div>
            </ul>
            <!-- <div style="clear:both"
                 v-show="page<maxpage && m_show===false">
              <el-button type="primary"
                         style="width:92vw;"
                         @click="getmoredata">获取更多</el-button>
            </div> -->
            <div v-show="m_show" style="text-align: left">
              <div class="m_detail_box">
                <div
                  style="
                    height: 10vw;
                    line-height: 10vw;
                    color: black;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ showobj.name }}
                </div>
                <el-image style="width: 90vw" :src="showobj.img" fit="contain"></el-image>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">参赛项目</div>
                <div>{{ showobj.xiangmu }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">组别</div>
                <div>{{ showobj.zubie }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">性别</div>
                <div>
                  <span v-if="showobj.sex === 1">男</span>
                  <span v-else>女</span>
                </div>
              </div>
              <div class="m_detail_box" v-show="!juesaishow">
                <div class="m_detail_box_item_1">初赛曲目</div>
                <div>{{ showobj.qumu }}</div>
              </div>
              <div class="m_detail_box" v-show="juesaishow">
                <div class="m_detail_box_item_1">决赛曲目</div>
                <div>{{ showobj.f_qumu }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">个人艺术简历曾获何种奖励</div>
                <div>{{ showobj.gereninfo }}</div>
              </div>
              <div class="m_detail_box" v-show="!juesaishow">
                <div class="m_detail_box_item_1">初赛演奏视频</div>
                <div v-if="spcontrol" style="width: 90vw">
                  <video-player
                    v-if="showobj.yzshipin"
                    width="320"
                    height="160"
                    :src="showobj.yzshipin"
                  />
                </div>
              </div>
              <div class="m_detail_box" v-show="juesaishow">
                <div class="m_detail_box_item_1">决赛演奏视频</div>
                <div v-if="spcontrol" style="width: 90vw">
                  <video-player
                    v-if="showobj.f_shipin"
                    width="320"
                    height="160"
                    :src="showobj.f_shipin"
                  />
                </div>
              </div>
              <div style="height: 50px"></div>
              <el-button
                type="primary"
                style="width: 90vw; position: fixed; bottom: 0; left: 5vw"
                @click="
                  m_show = false;
                  showobj = { yzshipin: false, f_shipin: false };
                "
                >关 闭</el-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab title="组合组">
          <div
            style="
              height: calc(100vh - 12vw - 44px - 20px);
              overflow-y: auto;
              margin: 10px auto;
              padding: 0 2vw;
              width: 96vw;
            "
          >
            <van-field
              v-model="kwd"
              clearable
              style="width: 92vw; margin: 0 2vw"
              v-show="m_show === false"
              placeholder="请输入姓名关键字模糊搜索"
            >
              <template #button>
                <van-button size="small" @click="dosousuo" type="info">搜索</van-button>
              </template>
            </van-field>
            <div style="height: 40px; line-height: 40px">
              <el-switch
                v-model="juesaishow"
                @change="changeBisai"
                active-text="决赛"
                style="font-size: 5vw"
                inactive-text="初赛"
              ></el-switch>
            </div>
            <div
              v-if="baomingdata.length === 0 && m_show === false"
              style="margin: 2vw auto"
            >
              无数据
            </div>
            <ul
              v-show="m_show === false"
              class="infinite-list"
              v-infinite-scroll="getmoredata"
              style="overflow: auto; height: calc(100vh - 12vw - 44px - 140px)"
            >
              <div
                v-for="(item, i) in baomingdata"
                class="m_bm_item_box"
                :key="i"
                @click="m_showdetail(item)"
              >
                <div style="height: 60vw">
                  <el-image
                    style="background: white; width: calc(45vw - 20px)"
                    :src="item.img"
                    fit="contain"
                  ></el-image>
                </div>
                <div
                  style="overflow: hidden; height: 8vw; line-height: 8vw; font-size: 4vw"
                >
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: left;
                      padding-left: 1vw;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: right;
                      padding-right: 1vw;
                    "
                    v-show="!juesaishow && item.xuhao != 0"
                  >
                    号码:{{ item.xuhao }}
                  </div>
                  <div
                    style="
                      float: left;
                      width: calc(21vw - 10px);
                      text-align: right;
                      padding-right: 1vw;
                    "
                    v-show="juesaishow && item.f_xuhao != 0"
                  >
                    号码:{{ item.f_xuhao }}
                  </div>
                </div>
              </div>
            </ul>
            <!-- <div style="clear:both"
                 v-show="page<maxpage && m_show===false">
              <el-button type="primary"
                         style="width:92vw;"
                         @click="getmoredata">获取更多</el-button>
            </div> -->
            <div v-show="m_show" style="text-align: left">
              <div class="m_detail_box">
                <div
                  style="
                    height: 10vw;
                    line-height: 10vw;
                    color: black;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ showobj.name }}
                </div>
                <el-image style="width: 90vw" :src="showobj.img" fit="contain"></el-image>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">参赛项目</div>
                <div>{{ showobj.xiangmu }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">组别</div>
                <div>{{ showobj.zubie }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">所有成员名字</div>
                <div>{{ showobj.hkszaidi }}</div>
              </div>
              <!-- <div class="m_detail_box">
                <div class="m_detail_box_item_1">性别</div>
                <div>
                  <span v-if="showobj.sex===1">男</span>
                  <span v-else>女</span>
                </div>
              </div> -->
              <div class="m_detail_box" v-show="!juesaishow">
                <div class="m_detail_box_item_1">初赛曲目</div>
                <div>{{ showobj.qumu }}</div>
              </div>
              <div class="m_detail_box" v-show="juesaishow">
                <div class="m_detail_box_item_1">决赛曲目</div>
                <div>{{ showobj.f_qumu }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">团体艺术简历曾获何种奖励</div>
                <div>{{ showobj.gereninfo }}</div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">初赛演奏视频</div>
                <div v-if="spcontrol && !juesaishow" style="width: 90vw">
                  <video-player
                    v-if="showobj.yzshipin"
                    width="320"
                    height="160"
                    :src="showobj.yzshipin"
                  />
                </div>
              </div>
              <div class="m_detail_box">
                <div class="m_detail_box_item_1">决赛演奏视频</div>
                <div v-if="spcontrol && juesaishow" style="width: 90vw">
                  <video-player
                    v-if="showobj.f_shipin"
                    width="320"
                    height="160"
                    :src="showobj.f_shipin"
                  />
                </div>
              </div>
              <div style="height: 50px"></div>
              <el-button
                type="primary"
                style="width: 90vw; position: fixed; bottom: 0; left: 5vw"
                @click="
                  m_show = false;
                  showobj = { yzshipin: false, f_shipin: false };
                "
                >关 闭</el-button
              >
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <el-dialog
      title="报名资料"
      :visible.sync="show"
      @close="showobj = { yzshipin: false, f_shipin: false }"
      width="600px"
    >
      <el-form
        ref="showobj"
        :rules="rules"
        label-position="top"
        :model="showobj"
        label-width="200px"
        style="text-align: left"
      >
        <el-form-item label="大赛报名" prop="dasaiid">
          {{ showobj.dasainame }}
        </el-form-item>
        <el-form-item v-show="active === 1" label="姓名" prop="name">
          {{ showobj.name }}
        </el-form-item>
        <el-form-item v-show="active !== 1" label="团体名称" prop="name">
          {{ showobj.name }}
        </el-form-item>
        <!-- <el-form-item label="证件号码"
                      prop="cardid">
          {{showobj.cardid}}
        </el-form-item>
        <el-form-item label="手机号"
                      prop="tel">
          {{showobj.tel}}
        </el-form-item> -->
        <el-form-item label="参赛项目" prop="xiangmu">
          {{ showobj.xiangmu }}
        </el-form-item>
        <el-form-item label="组别" prop="zubie">
          {{ showobj.zubie }}
        </el-form-item>
        <el-form-item v-show="active === 1" label="性别" prop="sex">
          <span v-if="showobj.sex === 1">男</span>
          <span v-else>女</span>
        </el-form-item>
        <el-form-item v-show="active === 1" label="年龄" prop="age">
          {{ showobj.age }}
        </el-form-item>
        <el-form-item v-show="active !== 1" label="所有成员名字">
          {{ showobj.hkszaidi }}
        </el-form-item>
        <el-form-item v-show="!juesaishow" label="初赛曲目" prop="qumu">
          {{ showobj.qumu }}
        </el-form-item>
        <el-form-item v-show="juesaishow" label="决赛曲目" prop="f_qumu">
          {{ showobj.f_qumu }}
        </el-form-item>
        <el-form-item label="辅导老师" prop="fudaolaoshi">
          {{ showobj.fudaolaoshi }}
        </el-form-item>
        <el-form-item v-show="active === 1" label="户口所在地">
          {{ showobj.hkszaidi }}
        </el-form-item>
        <el-form-item v-show="active === 1" label="个人艺术简历曾获何种奖励">
          {{ showobj.gereninfo }}
        </el-form-item>
        <el-form-item v-show="active !== 1" label="团体艺术简历曾获何种奖励">
          {{ showobj.gereninfo }}
        </el-form-item>
        <el-form-item label="上传生活照或艺术图片" prop="img">
          <img v-if="showobj.img" :src="showobj.img" class="avatar" />
        </el-form-item>
        <el-form-item
          v-if="spcontrol && !juesaishow"
          label="初赛演奏视频"
          prop="yzshipin"
        >
          <video-player
            v-if="showobj.yzshipin"
            width="554"
            height="400"
            :src="showobj.yzshipin"
          />
        </el-form-item>
        <el-form-item v-if="spcontrol && juesaishow" label="决赛演奏视频" prop="f_shipin">
          <video-player
            v-if="showobj.f_shipin"
            width="554"
            height="400"
            :src="showobj.f_shipin"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            show = false;
            showobj = { yzshipin: false, f_shipin: false };
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UploadMP4 from "@/components/UploadMP4.vue";
import VideoPlayer from "@/components/MyVideo.vue";

export default {
  data() {
    return {
      dasai_title: "广东省中国民族器乐大赛",
      js_img: "",
      zuheobj: {
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: "",
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
        zubie_2: "合奏",
      },
      addobj: {
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: "",
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
        zubie_2: "独奏",
      },
      myHeaders: { jwttoken: sessionStorage.getItem("jwt_token") },
      uploadurl: "https://www.gdnos.com.cn/api/test/uploadimg",
      fileList: [],
      dasaidata: [],
      dasaiid: 0,
      xiangmus: [],
      zubies: [],
      rules: {
        dasaiid: [{ required: true, message: "请选择大赛报名", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        cardid: [{ required: true, validator: this.IDvalidator, trigger: "blur" }],
        tel: [
          {
            required: true,
            validator: this.Telvalidator,
            trigger: "blur",
          },
        ],
        xiangmu: [
          {
            required: true,
            message: "请选择参赛项目",
            trigger: "blur",
          },
        ],
        zubie: [
          {
            required: true,
            message: "请选择参赛组别",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
          },
        ],
        age: [
          {
            required: true,
            message: "请输入年龄",
            trigger: "blur",
          },
        ],
        qumu: [
          {
            required: true,
            message: "请输入参赛曲目",
            trigger: "blur",
          },
        ],
        fudaolaoshi: [
          {
            required: true,
            message: "请输入辅导老师",
            trigger: "blur",
          },
        ],
        img: [
          {
            required: true,
            message: "请上传照片",
            trigger: "blur",
          },
        ],
        yzshipin: [
          {
            required: true,
            message: "请上传演奏视频",
            trigger: "blur",
          },
        ],
      },
      zhrules: {
        dasaiid: [{ required: true, message: "请选择大赛报名", trigger: "blur" }],
        name: [{ required: true, message: "请输入团体名称", trigger: "blur" }],
        tel: [
          {
            required: true,
            validator: this.Telvalidator,
            trigger: "blur",
          },
        ],
        xiangmu: [
          {
            required: true,
            message: "请选择参赛项目",
            trigger: "blur",
          },
        ],
        zubie: [
          {
            required: true,
            message: "请选择参赛组别",
            trigger: "blur",
          },
        ],
        hkszaidi: [
          {
            required: true,
            message: "请输入所有成员名字",
            trigger: "blur",
          },
        ],
        qumu: [
          {
            required: true,
            message: "请输入参赛曲目",
            trigger: "blur",
          },
        ],
        fudaolaoshi: [
          {
            required: true,
            message: "请输入辅导老师",
            trigger: "blur",
          },
        ],
        img: [
          {
            required: true,
            message: "请上传照片",
            trigger: "blur",
          },
        ],
        yzshipin: [
          {
            required: true,
            message: "请上传演奏视频",
            trigger: "blur",
          },
        ],
      },
      zubie_prop: {
        value: "text",
        label: "text",
        children: "data",
      },
      limitsize: 100 * 1024 * 1024, // 限制大小 11803166 11M
      label_width: "200px",
      ispc: true,
      showobj: {
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: "",
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
        zubie_2: "独奏",
      },
      m_show: false,
      show: false,
      active: 0,
      baomingdata: [],
      page: 1,
      size: 10,
      zubie_1: "",
      zubie_2: "",
      maxpage: 1,
      kwd: "",
      spcontrol: false,
      juesaishow: false,
    };
  },

  components: {
    UploadMP4,
    VideoPlayer,
  },

  computed: {},

  mounted() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      console.log("移动端");
      this.label_width = "100px";
      this.ispc = false;
    } else {
      console.log("pc端");
      this.label_width = "200px";
      this.ispc = true;
    }
    this.getdsdata();
    this.getcsxm();
    this.getzubie();
    this.getspcontrol();
    this.getjsImg();
  },

  methods: {
    getjsImg() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 9 })
        .then((res) => {
          console.log("获取数据返回", res);
          if (res.code === 200) {
            that.js_img = res.data.content;
          } else {
            that.$message.error("获取失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    getspcontrol() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 8 })
        .then((res) => {
          console.log("获取数据返回", res);
          if (res.code === 200) {
            if (res.data.content === "1") {
              that.spcontrol = true;
            } else {
              that.spcontrol = false;
            }
            console.log(that.spcontrol);
          } else {
            that.$message.error("获取失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    dosousuo() {
      this.page = 1;
      this.baomingdata = [];
      this.getdata();
    },
    getmoredata() {
      this.page += 1;
      this.getdata();
    },
    showdetail(val) {
      this.getspcontrol();
      this.showobj.yzshipin = "";
      this.showobj = JSON.parse(JSON.stringify(val));
      this.show = true;
    },
    m_showdetail(val) {
      this.getspcontrol();
      this.showobj.yzshipin = "";
      this.showobj = JSON.parse(JSON.stringify(val));
      this.m_show = true;
    },
    changeBisai() {
      this.baomingdata = [];
      this.page = 1;
      this.m_show = false;
      this.getdata();
    },
    // TODO 获取大赛报名数据
    getdata() {
      const that = this;
      if (this.juesaishow) {
        that.$axios
          .post("/fusai/getlist", {
            page: this.page,
            size: this.size,
            is_del: 0,
            jiaofei_sign: 1,
            zubie_1: this.zubie_1,
            zubie_2: this.zubie_2,
            name: this.kwd,
            dasaiid: 3,
          })
          .then((res) => {
            console.log("获取报名资料返回", res);
            if (res.code === 200) {
              that.baomingdata = that.baomingdata.concat(res.data.data);
              that.maxpage = Math.ceil(res.data.count / res.data.size);
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      } else {
        that.$axios
          .post("/dasaibaoming/getlist", {
            page: this.page,
            size: this.size,
            is_del: 0,
            jiaofei_sign: 1,
            zubie_1: this.zubie_1,
            zubie_2: this.zubie_2,
            name: this.kwd,
            dasaiid: 3,
          })
          .then((res) => {
            console.log("获取报名资料返回", res);
            if (res.code === 200) {
              that.baomingdata = that.baomingdata.concat(res.data.data);
              that.maxpage = Math.ceil(res.data.count / res.data.size);
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
    // TODO 点击标签跳转
    tabClick(name, title) {
      console.log("点击标签跳转", name, title);
      console.log("标签跳转", this.active);
      this.showobj = { yzshipin: false };
      if (name === 1) {
        return;
      }
      if (name === 2) {
        return;
      }
      this.baomingdata = [];
      this.page = 1;
      this.m_show = false;
      if (name === 3) {
        console.log("获取爱乐组数据");
        this.zubie_1 = "爱乐组";
        this.zubie_2 = "独奏";
        this.getdata();
        return;
      }
      if (name === 4) {
        console.log("获取专业组数组");
        this.zubie_1 = "专业组";
        this.zubie_2 = "独奏";
        this.getdata();
        return;
      }
      if (name === 5) {
        console.log("获取组合组数据");
        this.zubie_1 = "";
        this.zubie_2 = "合奏";
        this.getdata();
      }
    },
    // TODO 报名资料查询
    getbg() {
      const that = this;
      this.$prompt("请输入证件号码查询", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        inputErrorMessage: "证件号码不正确",
      })
        .then(({ value }) => {
          console.log("身份证查询", value);
          that.$axios
            .post("/dasaibaoming/getlist", {
              page: 1,
              size: 1,
              is_del: 0,
              cardid: value,
            })
            .then((res) => {
              console.log("获取报名资料返回", res);
              if (res.code === 200) {
                if (res.data.data.length > 0) {
                  [that.showobj] = res.data.data;
                  that.show = true;
                } else {
                  that.$message.info("未找到报名资料");
                }
              }
            })
            .catch((err) => {
              console.log("请求失败", err);
            });
        })
        .catch(() => {});
    },
    // TODO 获取全部大赛数据
    getdsdata() {
      const that = this;
      that.$axios
        .get("/dasai/getall_1")
        .then((res) => {
          console.log("报名中大赛返回", res);
          if (res.code === 200) {
            that.dasaidata = res.data;
            if (that.dasaidata.length > 0) {
              that.addobj.dasaiid = that.dasaidata[0].id;
              that.addobj.dasainame = that.dasaidata[0].name;
              that.zuheobj.dasaiid = that.dasaidata[0].id;
              that.zuheobj.dasainame = that.dasaidata[0].name;
              that.dasai_title = that.dasaidata[0].name;
            }
          }
        })
        .catch((err) => [console.log("请求失败", err)]);
    },
    // TODO 选择大赛
    selectds(val) {
      console.log("选中大赛", val);
      this.dasaidata.some((ele) => {
        if (ele.id === val) {
          if (this.active === 1) {
            this.addobj.dasainame = ele.name;
            this.dasai_title = ele.name;
          } else {
            this.zuheobj.dasainame = ele.name;
            this.dasai_title = ele.name;
          }
          return true;
        }
        return false;
      });
    },
    // TODO 视频上传返回
    uploadres(res) {
      console.log("上传组件返回值", res);
      if (res.code === 200) {
        if (this.active === 1) {
          this.addobj.yzshipin = `https://www.gdnos.com.cn/${res.msg}`;
        } else {
          this.zuheobj.yzshipin = `https://www.gdnos.com.cn/${res.msg}`;
        }
      }
    },
    // TODO 获取组别
    getzubie() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 6 })
        .then((res) => {
          console.log("组别返回", res);
          if (res.code === 200) {
            this.zubies = JSON.parse(res.data.content);
          } else {
            that.$message.error("获取组别失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 获取参赛项目
    getcsxm() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 5 })
        .then((res) => {
          console.log("参赛项目返回", res);
          if (res.code === 200) {
            that.xiangmus = JSON.parse(res.data.content);
          } else {
            that.$message.error("获取参赛项目失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // 港澳台证件号码码格式验证
    // 香港身份证校验
    isXgIDCard(idcard) {
      const checkIDCard = /^[A-Z]\d{6}\([\dA]\)$/;
      if (checkIDCard.test(idcard)) {
        let hash = (idcard.substring(0, 1).toUpperCase().charCodeAt() - 64) * 8;
        for (let i = 1; i <= 6; i += 1) {
          hash += parseInt(idcard.substring(i, i + 1), 10) * (8 - i);
        }
        console.log(hash);
        const validateNo = hash % 11;
        let ret = "";
        if (validateNo === 1) {
          ret = "A";
        } else {
          ret = 11 - validateNo;
        }
        if (idcard.substring(8, 9) === ret) {
          console.log("香港身份证通过校验");
          return true;
        }
        return false;
      }
      return false;
    },
    // 澳门身份证校验
    isAmIDCard(idcard) {
      const checkIDCard = /^[1|5|7]\d{6}[(\d)]{3}$/;
      if (checkIDCard.test(idcard)) {
        return true;
      }
      return false;
    },
    // 台湾身份证验证
    isTwIDCard(idcard) {
      const checkIDCard = /^[A-Z][1-2]\d{8}$/;
      if (checkIDCard.test(idcard)) {
        const area = {
          A: 10,
          B: 11,
          C: 12,
          D: 13,
          E: 14,
          F: 15,
          G: 16,
          H: 17,
          J: 18,
          K: 19,
          L: 20,
          M: 21,
          N: 22,
          P: 23,
          Q: 24,
          R: 25,
          S: 26,
          T: 27,
          U: 28,
          V: 29,
          X: 30,
          Y: 31,
          W: 32,
          Z: 33,
          I: 34,
          O: 35,
        };
        const idcard_array = idcard.split("");
        let jym =
          parseInt(area[idcard_array[0]] / 10, 10) +
          9 * (area[idcard_array[0]] % 10) +
          8 * idcard_array[1] +
          7 * idcard_array[2] +
          6 * idcard_array[3] +
          5 * idcard_array[4] +
          4 * idcard_array[5] +
          3 * idcard_array[6] +
          2 * idcard_array[7] +
          1 * idcard_array[8];
        jym = (10 - (jym % 10)) % 10;
        if (idcard_array[9] === jym) {
          return true;
        }
        return false;
      }
      return false;
    },
    // 护照校验
    checkPassport(idcard) {
      const myreg = /^[a-zA-Z0-9]{3,21}$/;
      const hz = /^(P\d{7})|(G\d{8})$/;
      if (!myreg.test(idcard)) {
        console.log("您输入的护照编号不正确!");
        return false;
      }
      if (!hz.test(idcard)) {
        console.log("您输入的护照编号不正确!");
        return false;
      }
      return true;
    },
    // 港澳居民内地通行证
    checkgapass(idcard) {
      const myreg = /^[HM]\d{10}$/;
      if (myreg.test(idcard)) {
        console.log("您输入的港澳居民来往内地通行证正确!");
        return true;
      }
      return false;
    },
    // TODO 身份证校验
    IDvalidator(rule, value, callback) {
      console.log("身份证验证", value);
      const test_xg = this.isXgIDCard(value);
      const test_am = this.isAmIDCard(value);
      const test_tw = this.isTwIDCard(value);
      const test_hz = this.checkPassport(value);
      const test_ga = this.checkgapass(value);
      console.log("港澳居民通行证", test_ga);
      // 身份证校验   /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
      const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (value === "") {
        callback(new Error("请输入证件号码"));
      } else if (test_ga) {
        callback();
      } else if (test_xg) {
        callback();
      } else if (test_tw) {
        callback();
      } else if (test_am) {
        callback();
      } else if (test_hz) {
        callback();
      } else if (p.test(value) && this.IdentityCodeValid(value)) {
        let sex_temp;
        if (parseInt(value.substr(16, 1), 10) % 2 === 1) {
          sex_temp = 1;
        } else {
          sex_temp = 2;
        }
        const myDate = new Date();
        const month = myDate.getMonth() + 1;
        const day = myDate.getDate();
        let age_temp = myDate.getFullYear() - value.substring(6, 10) - 1;
        if (
          value.substring(10, 12) < month ||
          (value.substring(10, 12) === month && value.substring(12, 14) <= day)
        ) {
          age_temp += 1;
        }
        this.addobj.sex = sex_temp;
        this.addobj.age = age_temp;
        callback();
      } else {
        callback();
      }
    },
    IdentityCodeValid(code) {
      const city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
      };
      let pass = true;

      if (
        !code ||
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(
          code
        )
      ) {
        console.log("证件号码格式错误");
        pass = false;
      } else if (!city[code.substr(0, 2)]) {
        console.log("地址编码错误");
        pass = false;
      } else {
        // 18位身份证需要验证最后一位校验位
        code = code.split("");
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验位
        const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
        let sum = 0;
        let ai = 0;
        let wi = 0;
        for (let i = 0; i < 17; i += 1) {
          ai = code[i];
          wi = factor[i];
          sum += ai * wi;
          console.log(sum);
        }
        const last = String(parity[sum % 11]);
        if (last.toLowerCase() !== code[17].toLowerCase()) {
          console.log("校验位错误");
          pass = false;
        }
      }
      return pass;
    },
    // TODO 手机号校验
    Telvalidator(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号有误"));
      } else {
        callback();
      }
    },
    // TODO 图片上传成功
    uploadsucess(res, file, fileList) {
      console.log("上传接口返回", res);
      console.log("文件", file);
      console.log("文件列表", fileList);
      if (res.code === 200) {
        if (this.active === 1) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("新建参赛图片", this.addobj);
        } else {
          this.zuheobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("新建参赛图片", this.zuheobj);
        }
      } else {
        this.$message.error("图片上传失败");
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload(file) {
      // this.uillUpdateImg = true
      this.$message.info("图片上传中，请等待。。。");
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = (e) => {
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob(dataURI, type) {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    // TODO 上传图片
    uploadError() {
      // 图片上传失败,关闭loading
      // this.uillUpdateImg = false
      this.$message.error("图片插入失败");
    },
    // TODO 执行add之前校验
    testadd() {
      const that = this;
      if (this.active === 1) {
        this.$refs.addobj.validate((valid) => {
          if (!valid) {
            console.log("error submit!!");
            return false;
          }
          that.doadd();
          return true;
        });
      } else {
        this.$refs.zuheobj.validate((valid) => {
          if (!valid) {
            console.log("error submit!!");
            return false;
          }
          that.doadd();
          return true;
        });
      }
    },
    // TODO 执行新增大赛报名
    doadd() {
      let obj = null;
      if (this.active === 1) {
        if (!this.addobj.dasaiid) {
          this.$message.info("请选择报名大赛");
          return;
        }
        if (!this.addobj.name) {
          this.$message.info("请输入姓名");
          return;
        }
        if (!this.addobj.cardid) {
          this.$message.info("请输入证件号码");
          return;
        }
        if (!this.addobj.tel) {
          this.$message.info("请输入手机号码");
          return;
        }
        if (!this.addobj.xiangmu) {
          this.$message.info("请选择参赛项目");
          return;
        }
        if (!this.addobj.zubie) {
          this.$message.info("请选择参赛组别");
          return;
        }
        if (!this.addobj.age) {
          this.$message.info("请输入年龄");
          return;
        }
        if (!this.addobj.qumu) {
          this.$message.info("请输入参赛曲目");
          return;
        }
        if (!this.addobj.fudaolaoshi) {
          this.$message.info("请输入辅导老师");
          return;
        }
        if (!this.addobj.img) {
          this.$message.info("请上传生活照或艺术照");
          return;
        }
        if (!this.addobj.yzshipin) {
          this.$message.info("请上传演奏视频");
          return;
        }
        obj = JSON.parse(JSON.stringify(this.addobj));
      } else {
        if (!this.zuheobj.dasaiid) {
          this.$message.info("请选择报名大赛");
          return;
        }
        if (!this.zuheobj.name) {
          this.$message.info("请输入团体名称");
          return;
        }
        if (!this.zuheobj.tel) {
          this.$message.info("请输入手机号码");
          return;
        }
        if (!this.zuheobj.xiangmu) {
          this.$message.info("请选择参赛项目");
          return;
        }
        if (!this.zuheobj.zubie) {
          this.$message.info("请选择参赛组别");
          return;
        }
        if (!this.zuheobj.hkszaidi) {
          this.$message.info("请输入所有成员名字");
          return;
        }
        if (!this.zuheobj.qumu) {
          this.$message.info("请输入参赛曲目");
          return;
        }
        if (!this.zuheobj.fudaolaoshi) {
          this.$message.info("请输入辅导老师");
          return;
        }
        if (!this.zuheobj.img) {
          this.$message.info("请上传生活照或艺术照");
          return;
        }
        if (!this.zuheobj.yzshipin) {
          this.$message.info("请上传演奏视频");
          return;
        }
        obj = JSON.parse(JSON.stringify(this.zuheobj));
      }
      obj.zubie = String(obj.zubie);
      const that = this;
      that.$axios
        .post("/dasaibaoming/add", obj)
        .then((res) => {
          console.log("添加大赛报名返回", res);
          if (res.code === 200) {
            that.$message.success("添加大赛报名成功");
            that.$alert("报名成功", "提示", {
              confirmButtonText: "确定",
              callback: () => {},
            });
            if (that.active === 1) {
              that.addobj = {
                dasaiid: "",
                dasainame: "",
                userid: 0,
                name: "",
                tel: "",
                xiangmu: "",
                zubie: [],
                sex: 1,
                age: 0,
                qumu: "",
                fudaolaoshi: "",
                cardid: "",
                hkszaidi: "",
                gereninfo: "",
                img: "",
                yzshipin: "",
                zubie_2: "独奏",
              };
            } else {
              that.zuheobj = {
                dasaiid: "",
                dasainame: "",
                userid: 0,
                name: "",
                tel: "",
                xiangmu: "",
                zubie: [],
                sex: 1,
                age: 0,
                qumu: "",
                fudaolaoshi: "",
                cardid: "",
                hkszaidi: "",
                gereninfo: "",
                img: "",
                yzshipin: "",
                zubie_2: "合奏",
              };
            }
          } else {
            that.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped>
.m_detail_box_item_1 {
  color: black;
  /* color: #d9ecff; */
  font-size: 5vw;
}
.m_detail_box {
  width: 90vw;
  padding: 2vw;
  margin: 2vw 1vw;
  background: white;
  border-radius: 5vw;
}
.m_bm_item_box {
  float: left;
  width: 45vw;
  margin: 1.5vw;
  padding: 10px;
  /* height: 90vw; */
  border: 1px solid #e8e8e8;
  /* #e8e8e8 */
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.pc_bm_item_box {
  float: left;
  width: 200px;
  margin: 5px 5px;
  padding: 10px;
  height: 350px;
  border: 1px solid #e8e8e8;
  /* #e8e8e8 */
  background: white;
  border-radius: 10px;
  overflow: hidden;
}
.right_btn {
  position: fixed;
  right: 0;
  top: 30%;
  width: 200px;
  text-align: right;
  z-index: 999;
  /* background: red; */
}
</style>
