<!-- 会长管理 -->
<template>
  <div>
    <div style="text-align: left; margin: 5px 0">
      <el-button type="primary" @click="add">新加会长</el-button>
      <el-select v-model="is_del" style="margin: 0 5px" placeholder="请选择">
        <el-option
          v-for="(item, i) in statuslist"
          :key="i"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="search">查询</el-button>
    </div>
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="name" align="center" label="名称" width="180">
      </el-table-column>
      <el-table-column label="头像" prop="img" align="center" width="180">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.img"
            fit="contain"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="renqi" align="center" label="任期" width="180">
      </el-table-column>
      <el-table-column prop="paixu" align="center" label="排序" width="180">
      </el-table-column>
      <el-table-column
        prop="info"
        align="center"
        label="介绍"
        show-overflow-tooltip
        width="180"
      >
      </el-table-column>
      <el-table-column prop="update_time" align="center" label="修改时间" width="180">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button
            v-if="scope.row.is_del === 0"
            type="danger"
            @click="del(scope.row, 1)"
            size="small"
            >隐藏</el-button
          >
          <el-button v-else type="success" @click="del(scope.row, 0)" size="small"
            >显示</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新建 -->
    <el-dialog title="新建" :visible.sync="addshow" width="80%">
      <el-form ref="addobj" :model="addobj" label-width="100px" style="text-align: left">
        <el-form-item label="会长名称">
          <el-input v-model="addobj.name" placeholder="请输入会长名称"></el-input>
        </el-form-item>
        <el-form-item label="会长头像">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="addobj.img" :src="addobj.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="任期">
          <el-input v-model="addobj.renqi" placeholder="请输入会长任期"></el-input>
        </el-form-item>
        <el-form-item label="会长介绍">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入会长介绍"
            v-model="addobj.info"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="排序 小的在前">
          <el-input
            v-model="addobj.paixu"
            type="number"
            placeholder="请输入排序 小的在前"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addshow = false">取 消</el-button>
        <el-button type="primary" @click="doadd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="editshow" width="80%">
      <el-form
        ref="editobj"
        :model="editobj"
        label-width="100px"
        style="text-align: left"
      >
        <el-form-item label="会长名称">
          <el-input v-model="editobj.name" placeholder="请输入会长名称"></el-input>
        </el-form-item>
        <el-form-item label="会长头像">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="editobj.img" :src="editobj.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="任期">
          <el-input v-model="editobj.renqi" placeholder="请输入会长任期"></el-input>
        </el-form-item>
        <el-form-item label="会长介绍">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入会长介绍"
            v-model="editobj.info"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="排序 小的在前">
          <el-input
            v-model="editobj.paixu"
            type="number"
            placeholder="请输入排序 小的在前"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editshow = false">取 消</el-button>
        <el-button type="primary" @click="doedit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      addshow: false,
      editshow: false,
      addobj: {
        name: "",
        img: "",
        renqi: "",
        info: "",
        paixu: 0,
      },
      editobj: {
        name: "",
        img: "",
        renqi: "",
        info: "",
        paixu: 0,
      },
      page: 1,
      size: 20,
      total: 0,
      myHeaders: { jwttoken: sessionStorage.getItem("jwt_token") },
      uploadurl: "https://www.gdnos.com.cn/api/test/uploadimg",
      fileList: [],
      is_del: 0,
      statuslist: [
        { id: 0, name: "显示" },
        { id: 1, name: "隐藏" },
      ],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.page = 1;
    this.getdata();
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getdata();
    },
    // TODO 查询
    search() {
      this.page = 1;
      this.getdata();
    },
    // TODO 图片上传成功
    uploadsucess(res, file, fileList) {
      console.log("上传接口返回", res);
      console.log("文件", file);
      console.log("文件列表", fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("新建会长上传封面图", this.addobj);
        }
        if (this.editshow) {
          this.editobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("编辑会长上传封面图", this.editobj);
        }
      } else {
        this.$message.error("图片上传失败");
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload(file) {
      // this.uillUpdateImg = true
      this.$message.info("图片上传中，请等待。。。");
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let { width } = image;
          let { height } = image;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > 1280 || height > 1280) {
            width = rate > 1 ? 1280 : 1280 * rate;
            height = rate < 1 ? 1280 : 1280 / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = 1280 / width > 1;
          const tate = 1280 / height > 1;
          if (!rate) {
            const product = 1280 / width;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          } else if (!tate) {
            const product = 1280 / height;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = (e) => {
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob(dataURI, type) {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    // TODO 获取会长数据
    getdata() {
      const that = this;
      this.$axios
        .post("/huizhang/getlist", {
          page: this.page,
          size: this.size,
          is_del: this.is_del,
        })
        .then((res) => {
          console.log("获取会长返回", res);
          if (res.code === 200) {
            that.tableData = res.data.data;
            that.total = res.data.count;
            that.size = res.data.size;
          } else {
            that.tableData = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 新增会长
    add() {
      console.log("新增会长");
      this.addobj = {
        name: "",
        img: "",
        renqi: "",
        info: "",
        paixu: 0,
      };
      this.addshow = true;
    },
    // TODO 执行新增会长
    doadd() {
      if (!this.addobj.name) {
        this.$message.info("请输入会长名称");
        return;
      }
      if (!this.addobj.img) {
        this.$message.info("请上传会长照片");
        return;
      }
      if (!this.addobj.renqi) {
        this.$message.info("请输入会长任期");
        return;
      }
      if (!this.addobj.info) {
        this.$message.info("请输入会长介绍");
        return;
      }
      this.addobj.paixu = parseInt(this.addobj.paixu, 10);
      const that = this;
      that.$axios
        .post("/huizhang/add", this.addobj)
        .then((res) => {
          console.log("添加会长返回", res);
          if (res.code === 200) {
            that.$message.success("添加会长成功");
            that.page = 1;
            that.is_del = 0;
            that.getdata();
            that.addshow = false;
          } else {
            that.$message.error("添加会长失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 编辑会长
    edit(data) {
      console.log("编辑会长", data);
      this.editobj = JSON.parse(JSON.stringify(data));
      this.editshow = true;
    },
    // TODO 执行编辑
    doedit() {
      if (!this.editobj.name) {
        this.$message.info("请输入会长名称");
        return;
      }
      if (!this.editobj.img) {
        this.$message.info("请上传会长照片");
        return;
      }
      if (!this.editobj.renqi) {
        this.$message.info("请输入会长任期");
        return;
      }
      if (!this.editobj.info) {
        this.$message.info("请输入会长介绍");
        return;
      }
      this.editobj.paixu = parseInt(this.editobj.paixu, 10);
      const that = this;
      that.$axios
        .post("/huizhang/update", this.editobj)
        .then((res) => {
          console.log("编辑会长返回", res);
          if (res.code === 200) {
            that.$message.success("编辑会长成功");
            that.getdata();
            that.editshow = false;
          } else {
            that.$message.error("编辑会长失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 隐藏显示会长
    del(data, sign) {
      console.log(`隐藏显示会长${sign}`, data);
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      const that = this;
      that.$axios
        .post("/huizhang/del", obj)
        .then((res) => {
          console.log("会长显示状态返回", res);
          if (res.code === 200) {
            that.$message.success("会长显示状态修改成功");
            that.getdata();
          } else {
            that.$message.error("会长显示状态修改失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
