<!-- 资讯类别管理 -->
<template>
  <div>
    <div style="text-align: left; margin: 5px 0">
      <el-button @click="add" type="primary">添加资讯类别</el-button>
    </div>
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="name" align="center" label="资讯类别" width="180">
      </el-table-column>
      <el-table-column label="状态" align="center" width="180">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_del === 0" type="success">显示</el-tag>
          <el-tag v-else type="danger">隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="序号" align="center" width="180">
        <template slot-scope="scope">
          <el-button @click="editxuhao(scope.row)" type="primary" size="small">{{
            scope.row.xuhao
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" align="center" label="修改时间" width="180">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button
            v-if="scope.row.is_del === 0"
            type="danger"
            @click="del(scope.row, 1)"
            size="small"
            >停用</el-button
          >
          <el-button v-else type="success" @click="del(scope.row, 0)" size="small"
            >启用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getdata();
  },

  methods: {
    // TODO 编辑资讯类别序号
    editxuhao(data) {
      console.log("编辑资讯类别序号", data);
      const that = this;
      this.$prompt("请输入资讯类别序号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: JSON.parse(JSON.stringify(data.xuhao)),
      })
        .then(({ value }) => {
          if (value === data.xuhao) {
            that.$message({
              type: "info",
              message: "内容无修改",
            });
          } else {
            that
              .$confirm("此操作将修改数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const obj = JSON.parse(JSON.stringify(data));
                obj.xuhao = parseInt(value, 10);
                that.$axios
                  .post("/ziXunType/updateZiXunType", obj)
                  .then((res) => {
                    console.log("编辑返回", res);
                    if (res.code === 200) {
                      that.getdata();
                    } else {
                      that.$message({
                        type: "warning",
                        message: "编辑失败!",
                      });
                    }
                  })
                  .catch((err) => {
                    console.log("请求失败", err);
                  });
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    // TODO 获取资讯类别
    getdata() {
      console.log("获取资讯类别");
      const that = this;
      this.$axios
        .get("/ziXunType/getAllZiXunType")
        .then((res) => {
          console.log("获取资讯类别返回", res);
          if (res.code === 200) {
            that.tableData = res.data;
          } else {
            that.tableData = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 新加资讯类别
    add() {
      console.log("新加资讯类别");
      const that = this;
      this.$prompt("请输入资讯类别名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          that.$axios
            .post("/ziXunType/addZiXunType", {
              name: value,
            })
            .then((res) => {
              console.log("新增资讯类别返回", res);
              if (res.code === 200) {
                that.getdata();
              } else {
                that.$message({
                  type: "warning",
                  message: "新增失败!",
                });
              }
            })
            .catch((err) => {
              console.log("请求失败", err);
            });
        })
        .catch(() => {});
    },
    // TODO 编辑资讯类别名称
    edit(data) {
      console.log("编辑资讯类别", data);
      const that = this;
      this.$prompt("请输入资讯类别", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: JSON.parse(JSON.stringify(data.name)),
      })
        .then(({ value }) => {
          if (value === data.name) {
            that.$message({
              type: "info",
              message: "内容无修改",
            });
          } else {
            that
              .$confirm("此操作将修改数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const obj = JSON.parse(JSON.stringify(data));
                obj.name = value;
                that.$axios
                  .post("/ziXunType/updateZiXunType", obj)
                  .then((res) => {
                    console.log("编辑返回", res);
                    if (res.code === 200) {
                      that.getdata();
                    } else {
                      that.$message({
                        type: "warning",
                        message: "编辑失败!",
                      });
                    }
                  })
                  .catch((err) => {
                    console.log("请求失败", err);
                  });
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    // TODO 停用启用资讯类别
    del(data, sign) {
      console.log(`启停用资讯类别${sign}`, data);
      const that = this;
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      that.$axios
        .post("/ziXunType/updateZiXunType", obj)
        .then((res) => {
          console.log("启停用资讯类别返回", res);
          that.getdata();
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
