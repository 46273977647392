<!-- 资讯详情 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <!-- overflow:hidden; -->
    <div style="flex: 1" class="max_width">
      <!-- 左边资讯详情 -->
      <div class="zixun_left_box" v-html="content"></div>
      <!-- 右边资讯推荐 -->
      <div class="zixun_right_box">
        <Zixuntuijian />
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Zixuntuijian from "@/components/ZiXunTuiJian.vue";

export default {
  data() {
    return {
      crumbs: [
        { text: "首页", to: { path: "/" } },
        { text: "驻会党建", to: { path: "/zhuhuidangjian" } },
        { text: "资讯详情" },
      ],
      content: "",
      id: 0,
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
    Zixuntuijian,
  },

  computed: {},

  mounted() {
    console.log(this.$route.path);
    console.log(this.$route.query);
    this.id = this.$route.query.id;
    this.getzixundetail();
  },

  methods: {
    // TODO 获取资讯明细
    getzixundetail() {
      const that = this;
      this.$axios
        .post("/ziXun/getZiXunById", { id: parseInt(this.id, 10) })
        .then((res) => {
          console.log("获取资讯详情返回", res);
          if (res.code === 200) {
            that.content = res.data.content;
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
