<!-- 其他委员会 -->
<template>
  <div class="main_area">
    <Head />
    <div style="flex: 1; width: 100vw" class="zjwyh_bg">
      <div class="max_width zjwyh_main">
        <div class="zjwyh_main_title_box">
          <div class="zjwyh_main_title">
            <span v-if="fenzhi.name">{{ fenzhi.name }}</span>
            <span v-else>陶笛艺术委员会</span>
          </div>
        </div>
        <div style="margin-top: 20px">
          <el-image v-if="fenzhi.heying" :src="fenzhi.heying" fit="contain"></el-image>
          <el-image
            v-else
            :src="require('@/assets/otherweiyuanhui/top_img.png')"
            fit="contain"
          ></el-image>
        </div>
        <div style="margin-top: 20px">
          <div class="otherwyh_small_title">
            <span v-if="fenzhi.name">{{ fenzhi.name }}</span>
            <span v-else>陶笛艺术委员会</span>介绍
          </div>
          <div class="otherwyh_small_text">
            <span v-if="fenzhi.jianshao">{{ fenzhi.jianshao }}</span>
            <span v-else
              >广东省民族管弦乐学会陶笛艺术委员会于2010年7月成立。本会是由致力于中国陶笛演奏、教学、研究、制作和推广的工作者组成的专业学术团体。本会宗旨是：以弘扬中华民族文化艺术为己任，以提高国民音乐文化素养为目标，以学校和社区教育为中心，全方位促进国内国际陶笛艺术交流为重点，致力于中国陶笛的演奏、教学、研究、制作和推广活动。我会的会训是：
              着眼大局，团结协作；恪尽职守，进取开拓；奉献激情，传递快乐！
            </span>
          </div>
        </div>
        <div style="margin-top: 20px">
          <div class="otherwyh_small_title">
            <span v-if="fenzhi.name">{{ fenzhi.name }}</span>
            <span v-else>陶笛艺术委员会</span>
            领导成员
          </div>
          <ul class="zjwyh_personnel_list">
            <li v-for="(item, i) in ld_data" style="margin-top: 5px; width: 25%" :key="i">
              <div class="zjwyh_personnel_item">
                <div style="font-weight: bold; font-size: 18px">{{ item.name }}</div>
                <div style="color: #ef7f5a; margin-top: 5px">{{ item.zhiwei }}</div>
              </div>
              <el-image
                v-show="item.img"
                :src="item.img"
                style="
                  height: 325px;
                  -webkit-justify-content: center;
                  justify-content: center;
                  -webkit-align-items: center;
                  align-items: center;
                  display: -webkit-flex;
                  display: flex;
                  overflow: hidden;
                "
                fit="cover"
              ></el-image>
            </li>
          </ul>
        </div>
        <div style="margin-top: 20px">
          <div class="otherwyh_small_title">
            <span v-if="fenzhi.name">{{ fenzhi.name }}</span>
            <span v-else>陶笛艺术委员会</span>
            资讯
          </div>
          <div class="otherwyh_zx">
            <router-link
              v-for="(info, j) in zx_data"
              class="zixun_list_item"
              :key="j"
              :to="'/zixundetail?id=' + info.id"
            >
              <div v-show="info.img" class="zixun_list_item_img_box">
                <el-image :src="info.img" fit="contain"></el-image>
              </div>
              <div class="zixun_list_item_text_box" style="text-align: left">
                <div class="zixun_list_item_text_box_titel">{{ info.title }}</div>
                <div class="zixun_list_item_text_box_text">{{ info.text }}</div>
                <div class="zixun_list_item_text_box_bottom">
                  <div class="zixun_list_item_text_box_bottom_time">
                    发布日期：{{ info.fabu_time }}
                  </div>
                  <div class="zixun_list_item_text_box_bottom_read">
                    {{ info.readnum }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";

export default {
  data() {
    return {
      zx_data: [],
      // {
      //   id: 2,
      //   img: '',
      //   title: '会员 | 广东省民族管弦乐学会2021年度第二批新会员名单',
      //   text: '为了提升学会服务品质，加强学术研讨氛围，回馈广大会员和民乐从业者，自即日起，广东省民族管弦乐学会会员均可报名申领学会主编的图书资料一部（书单详见下文），申领者可在文末留言区按如下方式留言：书稿名称+姓名+会员证号+收件地址+联系电话。邮件通过顺丰快递，费用自理。',
      //   fabu_time: '2021-03-30',
      //   readnum: 231,
      // }
      ld_data: [],
      // {
      //   name: '赖达富',
      //   zhiwei: '会长',
      //   img: require('@/assets/otherweiyuanhui/1.jpg'),
      // }, {
      //   name: '林烨',
      //   zhiwei: '常务副会长',
      //   img: require('@/assets/otherweiyuanhui/2.jpg'),
      // }, {
      //   name: '赵洪啸',
      //   zhiwei: '副会长',
      //   img: require('@/assets/otherweiyuanhui/3.jpg'),
      // }, {
      //   name: '蒋显敬',
      //   zhiwei: '副会长',
      //   img: require('@/assets/otherweiyuanhui/4.jpg'),
      // }, {
      //   name: '赵亮',
      //   zhiwei: '副会长',
      //   img: require('@/assets/otherweiyuanhui/5.jpg'),
      // }, {
      //   name: '徐昌茂',
      //   zhiwei: '副会长',
      //   img: require('@/assets/otherweiyuanhui/6.jpg'),
      // }, {
      //   name: '顾龙星',
      //   zhiwei: '副会长',
      //   img: require('@/assets/otherweiyuanhui/7.jpg'),
      // }, {
      //   name: '鲁晓',
      //   zhiwei: '秘书长',
      //   img: require('@/assets/otherweiyuanhui/9.jpg'),
      // }
      id: "",
      fenzhi: {
        heying: null,
        id: "",
        img: "",
        jianshao: null,
        lingdao: null,
        name: "",
      },
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
  },

  computed: {},

  mounted() {
    console.log("分支机构传参");
    if (this.$route.query) {
      this.id = this.$route.query.id;
      this.getfenzhi();
      this.getzixun();
    }
  },

  methods: {
    // TODO 获取分支资讯
    getzixun() {
      if (this.id) {
        const that = this;
        that.$axios
          .post("/ziXun/getZixunBylaiyuan", { id: parseInt(this.id, 10) })
          .then((res) => {
            console.log("获取分支资讯", res);
            if (res.code === 200) {
              res.data.data.forEach((element) => {
                element.text = element.jianjie;
              });
              that.zx_data = res.data.data;
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
    // TODO 获取分支机构数据
    getfenzhi() {
      if (this.id) {
        const that = this;
        that.$axios
          .post("/fenzhi/getOne", { id: parseInt(this.id, 10) })
          .then((res) => {
            console.log("获取分支机构", res);
            if (res.code === 200 && res.data) {
              that.fenzhi = res.data;
              if (res.data.lingdao) {
                that.ld_data = JSON.parse(res.data.lingdao);
              }
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
  },
};
</script>
<style scoped></style>
