<!-- 理事名单 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex: 1" class="max_width">
      <div class="lsmd_area">
        <div class="lsmd_area_title">广东省民族管弦乐学会理事会名单</div>
        <div class="lsmd_area_text2">
          <p style="font-size: 14px; text-align: center">（按姓氏笔画排序）</p>
          <span
            v-for="(item, i) in ls_data"
            :key="i"
            style="margin-top: 20px; font-size: 16px"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  data() {
    return {
      crumbs: [{ text: "首页", to: { path: "/" } }, { text: "理事名单" }],
      content: "",
      ls_data: [],
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
  },

  computed: {},

  mounted() {
    this.getdata();
  },

  methods: {
    // TODO 获取理事名单数据
    getdata() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 3 })
        .then((res) => {
          console.log("获取数据返回", res);
          if (res.code === 200) {
            that.ls_data = JSON.parse(res.data.content);
          } else {
            that.$message.error("获取失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
