<!-- 上传组件 -->
<template>
  <div>
    <el-upload
      class="upload-demo"
      ref="upload"
      :on-remove="handleRemove"
      :file-list="fileList"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-change="changeUpload"
      :limit="1"
      accept="video/mp4"
      :auto-upload="false"
    >
      <!-- accept="video/*" accept="video/mp4"-->
      <el-button slot="trigger" size="small" @click="fileList = []" type="primary"
        >选取文件</el-button
      >
      <el-button
        style="margin-left: 10px"
        size="small"
        type="success"
        @click="submitUpload"
        >上传到服务器</el-button
      >
      <div slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";

export default {
  props: ["tip", "limitsize"],
  data() {
    return {
      fileList: [],
      uploadList: [],
      md5: "",
      filetype: "",
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    changeUpload(file, fileList) {
      if (this.limitsize && this.limitsize < file.size) {
        console.log("限制大小", this.limitsize);
        this.$message.info("文件超出大小限制");
        this.fileList = [];
        return;
      }
      console.log("file", file);
      console.log("fileList", fileList);
      this.fileList = fileList;
    },
    submitUpload() {
      console.log("文件列表", this.fileList);
      if (this.fileList.length === 1) {
        // this.computeMD5(this.fileList[0].raw, 5 * 1024 * 1024); // 5M大小切割分片
        this.computeMD5(this.fileList[0].raw, 1 * 1024 * 1024); // 1M大小分割切片
        // eslint-disable-next-line prefer-destructuring
        this.filetype = this.fileList[0].raw.type.split("/")[1];
      } else {
        this.$message.info("请选择文件");
      }
      // this.$refs.upload.submit();
    },
    fileUplod() {
      console.log("文件mg5", this.md5);
      console.log("上传文件", this.uploadList);
      console.log("文件后缀", this.filetype);
      const n = this.uploadList.length;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "jwt-token":
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InJvbGVpZHMiOiIxLDIiLCJuYW1lIjoi5qiK5pm65YGlIiwidXNlcmlkIjoiMjQxMDIwMTkyMzI2OTIxMTQxIn0sImV4cCI6MTYyNTc5NzU4Mn0.G3wkao68m5YiUcWDckqOIsEULhJ0F6I4VC5_ExQNmP4",
        }, // 这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
      }; // 添加请求头
      this.doUpload(0, n, config);
    },
    getMp4() {
      const that = this;
      const loading = this.$loading({
        lock: true,
        text: "视频合成中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          "jwt-token":
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InJvbGVpZHMiOiIxLDIiLCJuYW1lIjoi5qiK5pm65YGlIiwidXNlcmlkIjoiMjQxMDIwMTkyMzI2OTIxMTQxIn0sImV4cCI6MTYyNTc5NzU4Mn0.G3wkao68m5YiUcWDckqOIsEULhJ0F6I4VC5_ExQNmP4",
        }, // 这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
      }; // 添加请求头
      const data = { fileMd5: this.md5 };
      setTimeout(() => {
        that.$axios
          .post("https://www.gdnos.com.cn/api/mp4/getMp4", data, config)
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              console.log("视频上传成功");
              loading.close();
              that.$emit("upload_res", res);
              that.$message.success("视频上传成功");
              return false;
            }
            if (res.code === 400) {
              console.log("视频合成中...");
              that.getMp4();
            }
            if (res.code === 300) {
              loading.close();
              console.log("上传失败");
            }
            return false;
          })
          .catch((err) => {
            console.log(err);
            loading.close();
          });
      }, 3000);
    },
    doUpload(i, n, config) {
      const that = this;
      const loading = this.$loading({
        lock: true,
        text: "视频上传中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const param = new FormData(); // 创建form对象
      param.append("type", this.filetype);
      param.append("total", n);
      param.append("fileMd5", this.md5);
      param.append("index", i);
      param.append("file", this.uploadList[i]); // 通过append向form对象添加数据
      that.$axios
        .post("https://www.gdnos.com.cn/api/mp4/uploadTempMp4", param, config)
        .then((res) => {
          // that.$axios.post('http://192.168.254.8:8080/mp4/uploadTempMp4', param, config).then((res) => {
          console.log(res);
          if (res.code === 200) {
            i += 1;
            that.doUpload(i, n, config);
            return false;
          }
          loading.close();
          if (res.code === 201) {
            console.log("上传成功,开始合成视频文件");
            that.getMp4();
          }
          if (res.code === 300) {
            console.log("上传失败");
          }
          return false;
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },
    getFile() {
      console.log(this.$refs.filElem.files);
      this.computeMD5(this.$refs.filElem.files[0], 5 * 1024 * 1024); // 5M大小切割分片
    },
    // 计算MD5
    computeMD5(file, chunkSize) {
      const loading = this.$loading({
        lock: true,
        text: "上传准备中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.uploadList = [];
      const time = new Date().getTime();
      const that = this;
      return new Promise((resolve, reject) => {
        const blobSlice =
          File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();
        function loadNext() {
          const start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size) {
            end = file.size;
          }
          that.uploadList.push(file.slice(start, end));
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          currentChunk += 1;
          if (currentChunk < chunks) {
            console.log(
              `第${currentChunk}分片解析完成, 开始第${
                currentChunk + 1
              } / ${chunks}分片解析`
            );
            loadNext();
          } else {
            loading.close();
            const md5 = spark.end();
            console.log("md5", md5);
            console.log(
              `MD5计算完成：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
                file.size
              } 用时：${new Date().getTime() - time} ms`
            );
            spark.destroy(); // 释放缓存
            that.md5 = md5;
            that.fileUplod();
            resolve(md5);
          }
        };

        fileReader.onerror = function (e) {
          reject(e);
        };
        loadNext();
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
<style scoped></style>
