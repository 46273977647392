<!-- 资讯管理 -->
<template>
  <div>
    <div style="text-align: left; margin: 5px 0">
      <el-button @click="add" style="margin-right: 5px" type="primary"
        >新加资讯</el-button
      >
      <el-select v-model="zixunType" placeholder="请选择资讯类别">
        <el-option
          v-for="(item, i) in zixunTypes"
          :key="i"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="kwd"
        style="width: 10vw; margin: 0 5px"
        placeholder="请输入标题关键词查询"
      ></el-input>
      <el-button type="primary" @click="search">查询</el-button>
    </div>
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="name" align="center" label="资讯类别" width="180">
      </el-table-column>
      <el-table-column label="标题" prop="title" align="center" width="180">
      </el-table-column>
      <el-table-column prop="fabu_time" align="center" label="发布时间" width="180">
      </el-table-column>
      <el-table-column prop="readnum" align="center" label="阅读人数" width="180">
      </el-table-column>
      <el-table-column prop="update_time" align="center" label="修改时间" width="180">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button
            v-if="scope.row.is_del === 0"
            type="danger"
            @click="del(scope.row, 1)"
            size="small"
            >隐藏</el-button
          >
          <el-button v-else type="success" @click="del(scope.row, 0)" size="small"
            >显示</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新建 -->
    <el-dialog title="新建" :visible.sync="addshow" width="80%">
      <el-form ref="addobj" :model="addobj" label-width="100px" style="text-align: left">
        <el-form-item label="资讯类别">
          <el-select v-model="addobj.typeid" placeholder="请选择资讯类别">
            <el-option
              v-for="(item, i) in zixunTypes"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            v-model="addobj.fabu_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择发布日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="addobj.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="封面图">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="addobj.img" :src="addobj.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="阅读人数">
          <el-input
            v-model="addobj.readnum"
            type="number"
            placeholder="请输入阅读人数"
          ></el-input>
        </el-form-item>
        <el-form-item label="资讯描述">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入资讯描述（第一段）"
            show-word-limit
            maxlength="200"
            v-model="addobj.jianjie"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="资讯内容">
          <div v-if="addshow">
            <quill-editor ref="myQuillEditor_edit" v-model="addobj.content" />
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addshow = false">取 消</el-button>
        <el-button type="primary" @click="doadd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="editshow" width="80%">
      <el-form
        ref="editobj"
        :model="editobj"
        label-width="100px"
        style="text-align: left"
      >
        <el-form-item label="资讯类别">
          <el-select v-model="editobj.typeid" placeholder="请选择资讯类别">
            <el-option
              v-for="(item, i) in zixunTypes"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            v-model="editobj.fabu_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择发布日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="editobj.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="封面图">
          <el-upload
            :action="uploadurl"
            :headers="myHeaders"
            name="file"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="uploadsucess"
          >
            <img v-if="editobj.img" :src="editobj.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="阅读人数">
          <el-input
            v-model="editobj.readnum"
            type="number"
            placeholder="请输入阅读人数"
          ></el-input>
        </el-form-item>
        <el-form-item label="资讯描述">
          <el-input
            type="textarea"
            :rows="3"
            maxlength="200"
            show-word-limit
            placeholder="请输入资讯描述（第一段）"
            v-model="editobj.jianjie"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="资讯内容">
          <div v-if="editshow">
            <quill-editor
              ref="myQuillEditor_edit"
              @ready="onEditorReady($event)"
              v-model="editobj.content"
            />
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editshow = false">取 消</el-button>
        <el-button type="primary" @click="doedit">确 定</el-button>
      </span>
    </el-dialog>
    <el-upload
      v-show="false"
      id="quill-upload"
      :action="uploadurl"
      :headers="myHeaders"
      name="file"
      multiple
      accept="image/png, image/jpeg, image/gif, image/jpg"
      :limit="1"
      :file-list="fileList"
      list-type="picture"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-error="uploadError"
      :on-success="handleExceed"
    >
    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      addobj: {
        typeid: "",
        fabu_time: "",
        readnum: 0,
        title: "",
        img: "",
        jianjie: "",
        content: "",
      },
      addshow: false,
      editobj: {
        id: "",
        typeid: "",
        fabu_time: "",
        readnum: 0,
        title: "",
        img: "",
        jianjie: "",
        content: "",
      },
      editshow: false,
      zixunTypes: [],
      zixunType: null,
      kwd: "",
      page: 1,
      size: 20,
      total: 0,
      content: "",
      myHeaders: { jwttoken: sessionStorage.getItem("jwt_token") },
      uploadurl: "https://www.gdnos.com.cn/api/test/uploadimg",
      fileList: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.gettypes();
    this.page = 1;
    this.getdata();
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.page = 1;
      this.getdata();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getdata();
    },
    // 查询
    search() {
      this.page = 1;
      this.getdata();
    },
    // TODO 图片上传成功
    uploadsucess(res, file, fileList) {
      console.log("上传接口返回", res);
      console.log("文件", file);
      console.log("文件列表", fileList);
      if (res.code === 200) {
        if (this.addshow) {
          this.addobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("新建资讯上传封面图", this.addobj);
        }
        if (this.editshow) {
          this.editobj.img = `https://www.gdnos.com.cn/${res.msg}`;
          console.log("编辑资讯上传封面图", this.editobj);
        }
      } else {
        this.$message.error("图片上传失败");
      }
    },
    // TODO 上传图片之前开启loading
    beforeUpload(file) {
      // this.uillUpdateImg = true
      this.$message.info("图片上传中，请等待。。。");
      const that = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let { width } = image;
          let { height } = image;
          const imgsize = 1280 * 4;
          // 等比缩放第一种 宽或高超过1280，进行等比例缩放
          let rate = image.width / image.height;
          if (width > imgsize || height > imgsize) {
            width = rate > 1 ? imgsize : imgsize * rate;
            height = rate < 1 ? imgsize : imgsize / rate;
          }

          // 等比缩放第二种 宽或高超过1280，等比缩放
          rate = imgsize / width > 1;
          const tate = imgsize / height > 1;
          if (!rate) {
            const product = imgsize / width;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          } else if (!tate) {
            const product = imgsize / height;
            width = Math.floor(width * product * 100) / 100;
            height = Math.floor(height * product * 100) / 100;
          }
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          const blobData = that.dataURItoBlob(dataUrl, file.type);
          resolve(blobData);
        };
        reader.onload = (e) => {
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    // TODO 压缩图片
    dataURItoBlob(dataURI, type) {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type });
    },
    // TODO 上传图片
    uploadError() {
      // 图片上传失败,关闭loading
      // this.uillUpdateImg = false
      this.$message.error("图片插入失败");
    },
    // TODO 图片添加成功
    handleExceed(response, file, fileList) {
      console.log("上传接口返回", response);
      console.log("文件", file);
      console.log("文件列表", fileList);
      // let quill;
      // if (this.addshow) {
      //   quill = this.$refs.myQuillEditor_add.quill;
      // }
      // if (this.editshow) {
      const { quill } = this.$refs.myQuillEditor_edit;
      // }
      if (response.code === 200) {
        this.$message.success("上传成功");
        const length = quill.getSelection().index;
        // 插入图片 response.data.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", `https://www.gdnos.com.cn/${response.msg}`);
        // console.log('图片路径', this.$picurl + response.msg)
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      this.fileList = [];
      this.uillUpdateImg = false;
    },
    // 富文本编辑区赋初值
    onEditorReady(e) {
      console.log("富文本编辑器准备", e);
      document.querySelector("div.ql-editor").innerHTML = this.editobj.content;
    },
    // TODO 获取资讯类别
    gettypes() {
      console.log("获取资讯类别");
      const that = this;
      this.$axios
        .get("/ziXunType/getZiXunType")
        .then((res) => {
          console.log("获取资讯类别返回", res);
          if (res.code === 200) {
            that.zixunTypes = res.data;
          } else {
            that.zixunTypes = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 获取资讯数据
    getdata() {
      const that = this;
      this.$axios
        .post("/ziXun/getZiXun", {
          typeid: this.zixunType,
          page: this.page,
          size: this.size,
          kwd: this.kwd,
        })
        .then((res) => {
          console.log("获取资讯返回", res);
          if (res.code === 200) {
            that.tableData = res.data.data;
            that.total = res.data.count;
            that.size = res.data.size;
          } else {
            that.tableData = [];
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 新增资讯
    add() {
      console.log("新增资讯");
      this.addobj = {
        typeid: "",
        fabu_time: this.$moment().format("YYYY-MM-DD"),
        readnum: 0,
        title: "",
        img: "",
        jianjie: "",
        content: "",
      };
      this.addshow = true;
    },
    // TODO 执行新增资讯
    doadd() {
      if (!this.addobj.typeid) {
        this.$message.info("请选择资讯类别");
        return;
      }
      if (!this.addobj.fabu_time) {
        this.$message.info("请选择发布时间");
        return;
      }
      if (!this.addobj.title) {
        this.$message.info("请输入标题");
        return;
      }
      if (!this.addobj.content || this.addobj.content === "<p><br></p>") {
        this.$message.info("请输入资讯内容");
        return;
      }
      const that = this;
      that.$axios
        .post("/ziXun/addZiXun", this.addobj)
        .then((res) => {
          console.log("添加资讯返回", res);
          if (res.code === 200) {
            that.$message.success("添加资讯成功");
            that.page = 1;
            that.zixunType = null;
            that.kwd = "";
            that.getdata();
            that.addshow = false;
          } else {
            that.$message.error("添加资讯失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 编辑资讯
    edit(data) {
      console.log("编辑资讯", data);
      this.editobj = JSON.parse(JSON.stringify(data));
      this.editshow = true;
    },
    // TODO 执行编辑
    doedit() {
      if (!this.editobj.typeid) {
        this.$message.info("请选择资讯类别");
        return;
      }
      if (!this.editobj.fabu_time) {
        this.$message.info("请选择发布时间");
        return;
      }
      if (!this.editobj.title) {
        this.$message.info("请输入标题");
        return;
      }
      if (!this.editobj.content || this.editobj.content === "<p><br></p>") {
        this.$message.info("请输入资讯内容");
        return;
      }
      const that = this;
      that.$axios
        .post("/ziXun/updateZiXun", this.editobj)
        .then((res) => {
          console.log("编辑资讯返回", res);
          if (res.code === 200) {
            that.$message.success("编辑资讯成功");
            that.getdata();
            that.editshow = false;
          } else {
            that.$message.error("编辑资讯失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 隐藏显示资讯
    del(data, sign) {
      console.log(`隐藏显示资讯${sign}`, data);
      const obj = JSON.parse(JSON.stringify(data));
      obj.is_del = sign;
      const that = this;
      that.$axios
        .post("/ziXun/updateZiXun", obj)
        .then((res) => {
          console.log("资讯显示状态返回", res);
          if (res.code === 200) {
            that.$message.success("资讯显示状态修改成功");
            that.getdata();
          } else {
            that.$message.error("资讯显示状态修改失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
