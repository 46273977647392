<!-- 常务理事名单 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex: 1" class="max_width">
      <div class="lsmd_area">
        <div class="lsmd_area_title">广东省民族管弦乐学会常务理事简介</div>
        <!-- <div class="lsmd_area_text">
          <p style="font-size:14px;">（按姓氏笔画排序）</p>
          <p v-for="(item,i) in ls_data"
             :key='i'
             style="margin-top:20px;font-size:16px;">{{item}}</p>
        </div> -->
        <div class="inputDeep">
          <el-input
            type="textarea"
            autosize
            readonly
            placeholder=""
            v-model="content"
          ></el-input>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  data() {
    return {
      crumbs: [{ text: "首页", to: { path: "/" } }, { text: "常务理事名单" }],
      content: "",
      ls_data: [],
      // '于庆新：中国音协《人民音乐》杂志编审、《人民音乐》编委，曾任副主编、中国音协民族音乐委员会副主任', '于红梅：中央音乐学院副院长，胡琴演奏家，教授、博导', '丰元凯：广东省民族管弦乐学会乐器改革制作专业委员会会长', '王天力：济南军区前卫文工团民族乐队指挥、国家一级演奏员（二胡）山东民族管弦乐学会副会长', '王少君：吉林省中外文化交流中心主任，吉林省民族管弦乐学会副会长', '王少谓：上海民族乐团指挥', '王中山：中国音乐学院古筝教授、博士生导师，古筝专业委员会会长', '王以东：中国音乐学院打击乐教授、博士生导师，打击乐专业委员会会长', '王书伟：中国广播艺术团原党委书记、团长，广东省民族管弦乐学会副会长兼秘书长，党支部书记', '王 宁：中国音乐学院作曲教授、硕士研究生导师', '王庆忠：吉林省民族乐团唢呐一级演奏员'
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
  },

  computed: {},

  mounted() {
    this.getlsmd();
  },

  methods: {
    // TODO 获取常务理事名单
    getlsmd() {
      const that = this;
      this.$axios
        .post("/webshezhi/get", {
          id: 2,
        })
        .then((res) => {
          console.log("获取常务理事名单返回", res);
          if (res.code === 200) {
            that.content = JSON.parse(res.data.content);
          } else {
            that.content = "";
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped>
.inputDeep >>> .el-textarea__inner {
  border: 0;
  /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
  resize: none;
  color: #666;
  margin-top: 30px;
  text-align: left;
}
</style>
