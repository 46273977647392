<!-- 学术研究 -->
<template>
  <div class="main_area">
    <Head />
    <!-- activeIndex="xueshuyanjiu" -->
    <div style="flex: 1" class="xsyj_area">
      <div class="max_width xsyj_area_content">
        <div style="text-align: left">
          <!-- <el-image :src="require('@/assets/学术研究_logo.png')"
                    fit="contain"></el-image> -->
        </div>
        <div class="xsyj_area_content_box">
          <div class="xsyj_area_content_title_box">
            <h3 class="xsyj_area_content_title">
              广东省民族管弦乐学会艺术发展研究中心介绍
            </h3>
          </div>
          <div
            style="
              padding: 0 30px;
              line-height: 2em;
              color: #999;
              text-indent: 2em;
              text-align: left;
              font-size: 14px;
            "
          >
            <!-- <p>2019年1月18日，广东省民族管弦乐学会艺术发展研究中心在京正式成立。广东省民族管弦乐学会会长刘锡津、副会长兼秘书长王书伟、副会长吴玉霞和来自20个专业委员会的专家代表共同见证中心启用。会议由王书伟副会长主持，刘锡津会长向大家介绍中心建立的初衷和基本功能定位，简要介绍了中心正在开展的“民乐艺术家口述史记录工程”项目和中心未来的课题规划。</p>
            <p>刘锡津会长在致辞中说，成立“艺术发展研究中心”是学会在新时代推动民乐事业向纵深发展的一项重要举措，是学会发展历史进程中的里程碑事件。中心将汇聚专家资源优势，对民族音乐的创作发展、学术研讨、人才培养、艺术普及和乐器改革等方面做深入研究，通过建设涵盖乐谱、音响、文字、影像等内容的数字化资源库，积极开展学术交流与共建活动，打造具有影响力的民乐科研平台，促进民乐在大文化概念下的成果转化，为服务国家文化发展战略做出一个社会组织的积极贡献。</p>
            <p>广东省民族管弦乐学会艺术发展研究中心（以下简称中心）是广东省民族管弦乐学会历经两年规划、一年具体实施而组建的。中心的成立将秉承学会弘扬中华民族音乐文化的宗旨，加强学会的学术功能建设，从推动中国民乐事业深化发展的现实需求出发，更好地为繁荣民族音乐事业做务实工作，同时向社会层面提供有关民乐活动的智库服务。中心业务将围绕“新时代民乐事业创新性发展”“重大课题研究及成果转化”“民乐艺术资料的收集、建档与整理”三部分开展工作。中心设有会议室供学会和各专业委员会研讨使用，还设有资料阅览室和音视频视听室，为民乐工作者提供优质服务。</p> -->
          </div>
        </div>
        <div
          class="xsyj_area_content_box"
          style="background: url(../assets/class_bg.png) no-repeat right bottom"
        >
          <div class="xsyj_area_content_title_box">
            <h3 class="xsyj_area_content_title">艺术发展研究中心规划课题</h3>
          </div>
          <div style="overflow: hidden" @click="showInfo">
            <div class="xsyj_area_content_img_box" style="margin-right: 20px">
              <router-link to="">
                <el-image
                  :src="require('@/assets/学术研究1.jpg')"
                  class="xsyj_area_content_img"
                  fit="contain"
                ></el-image>
              </router-link>
            </div>
            <div class="xsyj_area_content_img_box">
              <router-link to="">
                <el-image
                  :src="require('@/assets/学术研究2.jpg')"
                  class="xsyj_area_content_img"
                  fit="contain"
                ></el-image>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";

export default {
  components: {
    // HelloWorld,
    Head,
    Footer,
    RightFixBox,
  },
  data() {
    return {};
  },

  computed: {},

  mounted() {},

  methods: {
    // 提示数字化建设中
    showInfo() {
      this.$message.success("项目数字化建设中");
    },
  },
};
</script>
<style scoped></style>
