<!-- 大赛成绩查询 -->
<template>
  <div
    style="
      min-height: 100vh;
      display: flex;
      width: 100vw;
      flex-direction: column;
      background: #f0f0f0;
    "
  >
    <div
      v-if="ispc"
      style="
        font-weight: bold;
        text-align: center;
        font-size: 3.4vw;
        height: 6vw;
        line-height: 6vw;
      "
    >
      广东省中国民族器乐大赛成绩查询
    </div>
    <div
      v-else
      style="
        font-weight: bold;
        text-align: center;
        font-size: 6vw;
        height: 12vw;
        line-height: 12vw;
      "
    >
      广东省中国民族器乐大赛成绩查询
    </div>
    <div v-if="ispc" style="width: 50vw; margin: 0 auto">
      <el-form
        ref="addobj"
        :rules="rules"
        :model="addobj"
        label-position="left"
        :label-width="label_width"
        style="text-align: left"
      >
        <!-- <el-form-item label="证件号码"
                      prop="cardid">
          <el-input v-model="addobj.cardid"
                    placeholder="请输入证件号码"></el-input>
        </el-form-item> -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="addobj.name" placeholder="请输入参赛者名称"></el-input>
        </el-form-item>
        <el-form-item label="查询密码" prop="pwd">
          <el-input v-model="addobj.pwd" placeholder="请输入查询密码"></el-input>
        </el-form-item>
        <el-form-item v-if="show" label="大赛名称">
          {{ addobj.dasainame }}
        </el-form-item>
        <el-form-item v-if="show" label="初赛视频" prop="yzshipin">
          <video-player
            v-if="addobj.yzshipin"
            width="600"
            height="400"
            :src="addobj.yzshipin"
          />
        </el-form-item>
        <el-form-item v-if="show" label="初赛成绩">
          <span v-if="addobj.c_grade">{{ addobj.c_grade }}</span>
          <span v-else>成绩未出</span>
        </el-form-item>
        <el-form-item v-if="show" label="初赛备注">
          <span v-if="addobj.c_beizhu">{{ addobj.c_beizhu }}</span>
        </el-form-item>
        <el-form-item v-if="show && addobj.f_shipin" label="决赛视频" prop="yzshipin">
          <video-player
            v-if="addobj.f_shipin"
            width="600"
            height="400"
            :src="addobj.f_shipin"
          />
        </el-form-item>
        <el-form-item v-if="show && addobj.f_grade" label="决赛成绩">
          <span v-if="addobj.f_grade">{{ addobj.f_grade }}</span>
          <span v-else>成绩未出</span>
        </el-form-item>
        <el-form-item v-if="show && addobj.f_beizhu" label="决赛备注">
          <span v-if="addobj.f_beizhu">{{ addobj.f_beizhu }}</span>
        </el-form-item>
      </el-form>
      <!-- <el-button
        type="primary"
        v-if="baomingshow"
        style="width: 50vw; margin: 10px auto"
        @click="fusaibaoming"
        >决赛报名</el-button
      > -->
    </div>
    <div v-else style="width: 90vw; margin: 0 auto; text-align: left">
      <el-form
        ref="addobj"
        :rules="rules"
        :model="addobj"
        label-position="right"
        :label-width="label_width"
        style="text-align: left"
      >
        <!-- <el-form-item label="证件号码"
                      prop="cardid">
          <el-input v-model="addobj.cardid"
                    placeholder="请输入证件号码"></el-input>
        </el-form-item> -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="addobj.name" placeholder="请输入参赛者名称"></el-input>
        </el-form-item>
        <el-form-item label="查询密码" prop="pwd">
          <el-input v-model="addobj.pwd" placeholder="请输入查询密码"></el-input>
        </el-form-item>
        <el-form-item v-if="show" label="大赛名称">
          {{ addobj.dasainame }}
        </el-form-item>
        <el-form-item v-if="show" label="演奏视频" prop="yzshipin">
          <video-player
            v-if="addobj.yzshipin"
            width="200"
            height="160"
            :src="addobj.yzshipin"
          />
        </el-form-item>
        <el-form-item v-if="show" label="初赛成绩">
          <span v-if="addobj.c_grade">{{ addobj.c_grade }}</span>
          <span v-else>成绩未出</span>
        </el-form-item>
        <el-form-item v-if="show" label="初赛备注">
          <span v-if="addobj.c_beizhu">{{ addobj.c_beizhu }}</span>
        </el-form-item>
        <el-form-item v-if="show && addobj.f_shipin" label="决赛视频" prop="yzshipin">
          <video-player
            v-if="addobj.f_shipin"
            width="200"
            height="160"
            :src="addobj.f_shipin"
          />
        </el-form-item>
        <el-form-item v-if="show && addobj.f_grade" label="决赛成绩">
          <span v-if="addobj.f_grade">{{ addobj.f_grade }}</span>
          <span v-else>成绩未出</span>
        </el-form-item>
        <el-form-item v-if="show && addobj.f_beizhu" label="决赛备注">
          <span v-if="addobj.f_beizhu">{{ addobj.f_beizhu }}</span>
        </el-form-item>
      </el-form>
      <!-- <el-button type="primary"
                 v-if="baomingshow"
                 style="width:90vw;margin:10px 0;"
                 @click="fusaibaoming">决赛报名</el-button> -->
    </div>
    <div>
      <el-button type="primary" v-if="ispc" style="width: 50vw" @click="getchengji"
        >查 询 成 绩</el-button
      >
      <el-button type="primary" v-else style="width: 90vw" @click="getchengji"
        >查 询 成 绩</el-button
      >
    </div>
    <div v-if="ispc">
      <el-dialog title="决赛报名" :visible.sync="bmshow" width="50%">
        <el-form
          ref="fusaiobj"
          :rules="rules"
          :model="fusaiobj"
          label-position="left"
          label-width="200px"
          style="text-align: left"
        >
          <el-form-item label="姓名或团体名称" prop="name">
            <el-input
              v-model="fusaiobj.name"
              readonly
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="决赛曲目" prop="f_qumu">
            <el-input v-model="fusaiobj.f_qumu" placeholder="请输入参赛曲目"></el-input>
          </el-form-item>
          <el-form-item label="上传演奏视频" prop="f_shipin">
            <UploadMP4
              @upload_res="uploadres"
              :limitsize="limitsize"
              tip="只能上传mp4视频文件，且不超过100MB"
            />
            <video-player
              v-if="fusaiobj.f_shipin"
              width="320"
              height="240"
              :src="fusaiobj.f_shipin"
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bmshow = false">取 消</el-button>
          <el-button type="primary" @click="fusaisubmit">报 名</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="!ispc">
      <el-dialog title="决赛报名" :visible.sync="bmshow" width="90%">
        <el-form
          ref="fusaiobj"
          :rules="rules"
          :model="fusaiobj"
          label-position="right"
          label-width="100px"
          style="text-align: left"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="fusaiobj.name"
              readonly
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="决赛曲目" prop="f_qumu">
            <el-input v-model="fusaiobj.f_qumu" placeholder="请输入参赛曲目"></el-input>
          </el-form-item>
          <el-form-item label="上传演奏视频" prop="f_shipin">
            <UploadMP4
              @upload_res="uploadres"
              :limitsize="limitsize"
              tip="只能上传mp4视频文件，且不超过100MB"
            />
          </el-form-item>
          <video-player
            v-if="fusaiobj.f_shipin"
            width="280"
            height="200"
            :src="fusaiobj.f_shipin"
          />
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bmshow = false">取 消</el-button>
          <el-button type="primary" @click="fusaisubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/MyVideo.vue";
import UploadMP4 from "@/components/UploadMP4.vue";

export default {
  data() {
    return {
      addobj: {
        dasaiid: "",
        dasainame: "",
        userid: 0,
        name: "",
        tel: "",
        xiangmu: "",
        zubie: "",
        sex: 1,
        age: 0,
        qumu: "",
        fudaolaoshi: "",
        cardid: "",
        hkszaidi: "",
        gereninfo: "",
        img: "",
        yzshipin: "",
        pwd: "",
      },
      rules: {
        // cardid: [
        //   { required: true, validator: this.IDvalidator, trigger: 'blur' },
        // ],
        name: [{ required: true, message: "请输入参赛者名称", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入查询密码", trigger: "blur" }],
        f_qumu: [
          {
            required: true,
            message: "请输入参赛曲目",
            trigger: "blur",
          },
        ],
        f_shipin: [
          {
            required: true,
            message: "请上传演奏视频",
            trigger: "blur",
          },
        ],
      },
      limitsize: 100 * 1024 * 1024, // 限制大小 11803166 11M
      label_width: "200px",
      ispc: true,
      show: false,
      baomingshow: false,
      bmshow: false,
      fusaiobj: {},
      spcontrol: false,
    };
  },

  components: {
    UploadMP4,
    VideoPlayer,
  },

  mounted() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      console.log("移动端");
      this.label_width = "100px";
      this.ispc = false;
    } else {
      console.log("pc端");
      this.label_width = "200px";
      this.ispc = true;
    }
    this.getspcontrol();
  },

  methods: {
    getspcontrol() {
      const that = this;
      that.$axios
        .post("/webshezhi/get", { id: 8 })
        .then((res) => {
          console.log("获取数据返回", res);
          if (res.code === 200) {
            if (res.data.content === "1") {
              that.spcontrol = true;
            } else {
              that.spcontrol = false;
            }
            console.log(that.spcontrol);
          } else {
            that.$message.error("获取失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 视频上传返回
    uploadres(res) {
      console.log("上传组件返回值", res);
      if (res.code === 200) {
        this.fusaiobj.f_shipin = `https://www.gdnos.com.cn/${res.msg}`;
      }
    },
    // 提交决赛报名
    fofusaisubmit() {
      console.log("决赛视频", this.fusaiobj);
      const that = this;
      const obj = JSON.parse(JSON.stringify(this.fusaiobj));
      this.$axios.post("/fusai/updateFuSai", obj).then((res) => {
        console.log("添加大赛报名返回", res);
        if (res.code === 200) {
          that.$message.success("添加大赛报名成功");
          that.$alert("报名成功", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
        }
      });
    },
    // 决赛报名前校验
    fusaisubmit() {
      const that = this;
      this.$refs.fusaiobj.validate((valid) => {
        if (!valid) {
          console.log("error submit!!");
          return false;
        }
        that.fofusaisubmit();
        return true;
      });
    },
    // 显示决赛报名框 填写曲目上传视频 提交报名
    fusaibaoming() {
      this.fusaiobj = {
        id: this.addobj.id,
        name: this.addobj.name,
        f_qumu: "",
        f_shipin: "",
        is_f: 1,
        c_grade: this.addobj.c_grade,
        c_beizhu: this.addobj.c_beizhu,
      };
      this.bmshow = true;
      console.log("1");
    },
    // 港澳台证件号码码格式验证
    // 香港身份证校验
    isXgIDCard(idcard) {
      const checkIDCard = /^[A-Z]\d{6}\([\dA]\)$/;
      if (checkIDCard.test(idcard)) {
        let hash = (idcard.substring(0, 1).toUpperCase().charCodeAt() - 64) * 8;
        for (let i = 1; i <= 6; i += 1) {
          hash += parseInt(idcard.substring(i, i + 1), 10) * (8 - i);
        }
        console.log(hash);
        const validateNo = hash % 11;
        let ret = "";
        if (validateNo === 1) {
          ret = "A";
        } else {
          ret = 11 - validateNo;
        }
        if (idcard.substring(8, 9) === ret) {
          console.log("香港身份证通过校验");
          return true;
        }
        return false;
      }
      return false;
    },
    // 澳门身份证校验
    isAmIDCard(idcard) {
      const checkIDCard = /^[1|5|7]\d{6}[(\d)]{3}$/;
      if (checkIDCard.test(idcard)) {
        return true;
      }
      return false;
    },
    // 台湾身份证验证
    isTwIDCard(idcard) {
      const checkIDCard = /^[A-Z][1-2]\d{8}$/;
      if (checkIDCard.test(idcard)) {
        const area = {
          A: 10,
          B: 11,
          C: 12,
          D: 13,
          E: 14,
          F: 15,
          G: 16,
          H: 17,
          J: 18,
          K: 19,
          L: 20,
          M: 21,
          N: 22,
          P: 23,
          Q: 24,
          R: 25,
          S: 26,
          T: 27,
          U: 28,
          V: 29,
          X: 30,
          Y: 31,
          W: 32,
          Z: 33,
          I: 34,
          O: 35,
        };
        const idcard_array = idcard.split("");
        let jym =
          parseInt(area[idcard_array[0]] / 10, 10) +
          9 * (area[idcard_array[0]] % 10) +
          8 * idcard_array[1] +
          7 * idcard_array[2] +
          6 * idcard_array[3] +
          5 * idcard_array[4] +
          4 * idcard_array[5] +
          3 * idcard_array[6] +
          2 * idcard_array[7] +
          1 * idcard_array[8];
        jym = (10 - (jym % 10)) % 10;
        if (idcard_array[9] === jym) {
          return true;
        }
        return false;
      }
      return false;
    },
    // 护照校验
    checkPassport(idcard) {
      const myreg = /^[a-zA-Z0-9]{3,21}$/;
      const hz = /^(P\d{7})|(G\d{8})$/;
      if (!myreg.test(idcard)) {
        console.log("您输入的护照编号不正确!");
        return false;
      }
      if (!hz.test(idcard)) {
        console.log("您输入的护照编号不正确!");
        return false;
      }
      return true;
    },
    checkgapass(idcard) {
      const myreg = /^[HM]\d{10}$/;
      if (myreg.test(idcard)) {
        console.log("您输入的港澳居民来往内地通行证正确!");
        return true;
      }
      return false;
    },
    // TODO 身份证校验
    IDvalidator(rule, value, callback) {
      console.log("身份证验证", value);
      const test_xg = this.isXgIDCard(value);
      const test_am = this.isAmIDCard(value);
      const test_tw = this.isTwIDCard(value);
      const test_hz = this.checkPassport(value);
      const test_ga = this.checkgapass(value);
      // 身份证校验   /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
      const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (value === "") {
        callback(new Error("请输入证件号码"));
      } else if (test_ga) {
        callback();
      } else if (test_xg) {
        callback();
      } else if (test_tw) {
        callback();
      } else if (test_am) {
        callback();
      } else if (test_hz) {
        callback();
      } else if (p.test(value) && this.IdentityCodeValid(value)) {
        let sex_temp;
        if (parseInt(value.substr(16, 1), 10) % 2 === 1) {
          sex_temp = 1;
        } else {
          sex_temp = 2;
        }
        const myDate = new Date();
        const month = myDate.getMonth() + 1;
        const day = myDate.getDate();
        let age_temp = myDate.getFullYear() - value.substring(6, 10) - 1;
        if (
          value.substring(10, 12) < month ||
          (value.substring(10, 12) === month && value.substring(12, 14) <= day)
        ) {
          age_temp += 1;
        }
        this.addobj.sex = sex_temp;
        this.addobj.age = age_temp;
        callback();
      } else {
        callback();
      }
    },
    IdentityCodeValid(code) {
      const city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
      };
      let pass = true;

      if (
        !code ||
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(
          code
        )
      ) {
        console.log("证件号码格式错误");
        pass = false;
      } else if (!city[code.substr(0, 2)]) {
        console.log("地址编码错误");
        pass = false;
      } else {
        // 18位身份证需要验证最后一位校验位
        code = code.split("");
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验位
        const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
        let sum = 0;
        let ai = 0;
        let wi = 0;
        for (let i = 0; i < 17; i += 1) {
          ai = code[i];
          wi = factor[i];
          sum += ai * wi;
        }
        const last = String(parity[sum % 11]);
        if (last.toLowerCase() !== code[17].toLowerCase()) {
          console.log("校验位错误");
          pass = false;
        }
      }
      return pass;
    },
    // TODO 手机号校验
    Telvalidator(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号有误"));
      } else {
        callback();
      }
    },
    // TODO 执行add之前校验
    getchengji() {
      console.log(this.addobj);
      const that = this;
      this.show = false;
      // this.addobj.pwd = this.addobj.pwd.replace(/\s*/g, '');
      // this.addobj.cardid = this.addobj.cardid.replace(/\s*/g, '');
      // this.addobj.name = this.addobj.name.replace(/\s*/g, '');
      this.$refs.addobj.validate((valid) => {
        if (!valid) {
          console.log("error submit!!");
          return false;
        }
        that.dogetchengji();
        return true;
      });
    },
    // TODO 执行新增大赛报名
    dogetchengji() {
      const that = this;
      that.$axios
        .post("/fusai/getchengji", {
          name: this.addobj.name.replace(/\s*/g, ""),
          tel: this.addobj.pwd.replace(/\s*/g, ""),
        })
        .then((res) => {
          console.log("获取报名资料返回", res);
          if (res.code === 200) {
            that.addobj = res.data;
            that.show = true;
            if (that.addobj.is_f === 1 && !that.addobj.f_qumu) {
              that.baomingshow = true;
            } else {
              that.baomingshow = false;
            }
          } else {
            that.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped>
.right_btn {
  position: fixed;
  right: 0;
  top: 30%;
  width: 200px;
  text-align: right;
  /* background: red; */
}
</style>
