<!--  -->
<template>
  <div>
    <div
      style="
        width: 500px;
        height: 300px;
        box-sizing: border-box;
        padding: 0 50px;
        border-radius: 5px;
        box-shadow: 0px 2px 12px 0px rgb(105 105 105 / 7%);
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: mymove 1s ease-in-out alternate;
        overflow: hidden;
        transition: 1.5s;
      "
    >
      <div
        style="
          width: 300px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div
          style="
            text-align: center;
            font-size: 24px;
            margin-bottom: 30px;
            font-weight: bold;
            color: white;
          "
        >
          <p>管理员登录</p>
        </div>
        <el-input
          v-model="user"
          style="margin-bottom: 25px"
          clearable
          placeholder="用户名"
        ></el-input>
        <el-input
          v-model="pwd"
          clearable
          style="margin-bottom: 25px"
          show-password
          placeholder="密码"
        ></el-input>
        <div style="margin-top: 10px">
          <el-button type="primary" style="width: 100%" @click="login">登录</el-button>
        </div>
      </div>
    </div>
    <!-- 粒子特效 -->
    <div style="width: 100vw; height: 100vh; overflow: hidden; background-color: black">
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="6"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="6"
        :hoverEffect="true"
        hoverMode="repulse"
        :clickEffect="true"
        clickMode="repulse"
      >
      </vue-particles>
      <!-- 参数说明
        color: String类型。默认'#dedede'。粒子颜色。
        particleOpacity: Number类型。默认0.7。粒子透明度。
        particlesNumber: Number类型。默认80。粒子数量。
        shapeType: String类型。默认'circle'。可用的粒子外观类型有："circle","edge","triangle", "polygon","star"。
        particleSize: Number类型。默认80。单个粒子大小。
        linesColor: String类型。默认'#dedede'。线条颜色。
        linesWidth: Number类型。默认1。线条宽度。
        lineLinked: 布尔类型。默认true。连接线是否可用。
        lineOpacity: Number类型。默认0.4。线条透明度。
        linesDistance: Number类型。默认150。线条距离。
        moveSpeed: Number类型。默认3。粒子运动速度。
        hoverEffect: 布尔类型。默认true。是否有hover特效。
        hoverMode: String类型。默认true。可用的hover模式有: "grab", "repulse", "bubble"。
        clickEffect: 布尔类型。默认true。是否有click特效。
        clickMode: String类型。默认true。可用的click模式有: "push", "remove", "repulse", "bubble"。 -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: "",
      pwd: "",
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    // TODO 登录
    login() {
      const that = this;
      that.$axios
        .post("/login/login", {
          tel: this.user,
          pwd: this.pwd,
        })
        .then((res) => {
          console.log("管理员登录返回", res);
          if (res.code === 200) {
            that.$message.success("登录成功");
            sessionStorage.setItem("jwt_token", res.data);
            sessionStorage.setItem("role", 1);
            that.$router.push({ path: "/admin/center" });
          } else {
            that.$message.error("登录失败");
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
