<!-- 现任领导简介 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex: 1" class="max_width">
      <div
        style="
          width: 1200px;
          box-sizing: border-box;
          box-shadow: 0 0 10px #d9e4ec;
          border-radius: 10px;
          padding: 40px;
          margin: 30px auto;
        "
      >
        <div class="xhgk_title_box">
          <div class="xhgk_title">广东省民族管弦乐学会现任领导介绍</div>
        </div>
        <div style="margin-top: 10px; font-size: 14px">
          Introduction to the Current Leaders of Chinese National Orchestra
        </div>
        <div style="margin-top: 40px; overflow: hidden">
          <el-tabs v-model="activeName" @tab-click="tabclick">
            <el-tab-pane v-for="(item, i) in ld_data" :key="i" :name="i + ''">
              <span slot="label" class="xrld_top_item_text_box">
                <div style="font-size: 18px">{{ item.name }}</div>
                <div
                  class="xrld_top_item_text"
                  :style="{ color: i + '' == activeName ? '#409EFF' : '' }"
                >
                  {{ item.zhiwei }}
                </div>
              </span>
            </el-tab-pane>
          </el-tabs>
          <el-carousel
            height="630px"
            :initial-index="activate"
            @change="ldchange"
            ref="carousel"
            :autoplay="false"
            indicator-position="none"
          >
            <el-carousel-item v-for="(item, i) in ld_data" :key="i">
              <div class="xrld_middle_box_area">
                <div class="xrld_middle_box_area_img">
                  <el-image :src="item.img" fit="contain"></el-image>
                </div>
                <div class="xrld_middle_box_area_text">
                  <div class="xrld_middle_box_area_text_text1">{{ item.info }}</div>
                  <div class="xrld_middle_box_area_text_text2">
                    <span class="xrld_middle_box_area_text_text2_1">{{ item.name }}</span>
                    <span class="xrld_middle_box_area_text_text2_2">{{
                      item.zhiwei
                    }}</span>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  data() {
    return {
      activeName: "0",
      activate: 0,
      crumbs: [{ text: "首页", to: { path: "/" } }, { text: "现任领导介绍" }],
      content: "",
      ld_data: [],
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
  },

  computed: {},

  mounted() {
    this.getdata();
  },

  methods: {
    // TODO 获取现任领导数据
    getdata() {
      const that = this;
      this.$axios
        .post("/lingdao/getlist", {
          page: 1,
          size: 20,
          is_del: 0,
        })
        .then((res) => {
          console.log("获取领导返回", res);
          if (res.code === 200) {
            that.ld_data = res.data.data;
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
    // TODO 标签页变动
    tabclick(e) {
      console.log("tabclick", e.index);
      this.$refs.carousel.setActiveItem(parseInt(e.index, 10));
    },
    // TODO 轮播图变动
    ldchange(i) {
      console.log("轮播图改变", i);
      this.activeName = `${i}`;
      console.log(this.activeName);
    },
  },
};
</script>
<style scoped></style>
