<!-- 驻会人员 -->
<template>
  <div class="main_area">
    <Head />
    <Breadcrumb :crumbs="crumbs" />
    <div style="flex: 1" class="max_width">
      <div class="zhry_area">
        <div class="zhry_area_title">
          <div class="zhry_area_title_text">广东省民族管弦乐学会驻会工作人员</div>
        </div>
        <div style="overflow: hidden">
          <div class="three_img_box" v-for="(item, i) in peo_data" :key="i">
            <div class="three_img_box_img">
              <el-image :src="item.img" fit="contain"></el-image>
            </div>
            <div class="three_img_box_text_area">
              <div style="font-weight: bold; font-size: 18px">
                {{ item.name }}
              </div>
              <!-- v-for="(zhiwei, j) in item.zhiwei"
                :key="j"  -->
              <div style="color: #ef7f5a; margin-top: 5px; font-size: 14px">
                {{ item.zhiwei }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RightFixBox />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightFixBox from "@/components/RightFixBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  data() {
    return {
      crumbs: [{ text: "首页", to: { path: "/" } }, { text: "驻会人员介绍" }],
      peo_data: [
        {
          img: require("@/assets/peo_1.png"),
          name: "刘锡津",
          zhiwei: ["专家委员会主任"],
        },
      ],
      // {
      //   img: require('@/assets/peo_1.png'),
      //   name: '刘锡津',
      //   zhiwei: ['专家委员会主任'],
      // }, {
      //   img: require('@/assets/peo_2.png'),
      //   name: '吴玉霞',
      //   zhiwei: ['会长'],
      // }, {
      //   img: require('@/assets/peo_3.png'),
      //   name: '王书伟',
      //   zhiwei: ['常务副会长兼秘书长'],
      // }, {
      //   img: require('@/assets/peo_4.png'),
      //   name: '刘峪升',
      //   zhiwei: ['顾问'],
      // }, {
      //   img: require('@/assets/peo_5.png'),
      //   name: '张履福',
      //   zhiwei: ['副秘书长', '办公室主任'],
      // }, {
      //   img: require('@/assets/peo_6.png'),
      //   name: '宋阳',
      //   zhiwei: ['副秘书长'],
      // }, {
      //   img: require('@/assets/peo_7.png'),
      //   name: '刘全国',
      //   zhiwei: ['办公室副主任', '考级工作小组副组长'],
      // }, {
      //   img: require('@/assets/peo_8.png'),
      //   name: '刘海燕',
      //   zhiwei: ['办公室副主任'],
      // }, {
      //   img: require('@/assets/peo_9.png'),
      //   name: '闫振海',
      //   zhiwei: ['办公室副主任', '考级工作小组副团长'],
      // }, {
      //   img: require('@/assets/peo_10.png'),
      //   name: '刁艳',
      //   zhiwei: ['《中国民乐报》执行副主编'],
      // }, {
      //   img: require('@/assets/peo_11.png'),
      //   name: '王飞',
      //   zhiwei: ['财务'],
      // }, {
      //   img: require('@/assets/peo_12.png'),
      //   name: '张秋媛',
      //   zhiwei: ['财务'],
      // }, {
      //   img: require('@/assets/peo_13.png'),
      //   name: '邓娅玲',
      //   zhiwei: ['办公室'],
      // }, {
      //   img: require('@/assets/peo_14.png'),
      //   name: '刘昊',
      //   zhiwei: ['《中国民乐报》编辑'],
      // }, {
      //   img: require('@/assets/peo_15.png'),
      //   name: '张殿新',
      //   zhiwei: ['考级工作小组副组长'],
      // }, {
      //   img: require('@/assets/peo_16.png'),
      //   name: '田龙',
      //   zhiwei: ['考级工作小组副组长'],
      // }
    };
  },

  components: {
    Head,
    Footer,
    RightFixBox,
    Breadcrumb,
  },

  computed: {},

  mounted() {
    this.getdata();
  },

  methods: {
    // TODO 获取驻会工作人员数据
    getdata() {
      const that = this;
      this.$axios
        .post("/gongzuorenyuan/getlist", {
          page: 1,
          size: 30,
          is_del: 0,
        })
        .then((res) => {
          console.log("获取工作人员返回", res);
          if (res.code === 200) {
            that.peo_data = res.data.data;
          }
        })
        .catch((err) => {
          console.log("请求失败", err);
        });
    },
  },
};
</script>
<style scoped></style>
